/* eslint-disable */
import React, { PureComponent } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from 'prop-types';
import ChevronDoubleRightIcon from 'mdi-react/ChevronDoubleRightIcon';
import ChevronDoubleLeftIcon from 'mdi-react/ChevronDoubleLeftIcon';
import { partial } from '../../../util/translation/translation';
import { range } from '../../../util/functions';

const p = partial('Pagination');
export default class PaginationComponent extends PureComponent {
  static propTypes = {
    items: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    rowsPerPage: PropTypes.arrayOf(PropTypes.number),
  };

  static defaultProps = {
    rowsPerPage: [10, 20, 30],
  };

  constructor(props) {
    super(props);
    this.state = { pager: {}, initialPage: 1, size: this.props.rowsPerPage[0] };
  }

  componentWillMount() {
    // set page if items array isn't empty
    this.setPage(this.props.initialPage);
  }

  componentDidUpdate(prevProps, prevState) {
    // reset page if items array has changed
    if (this.props.items !== prevProps.items) {
      this.setPage(this.props.initialPage);
    }
  }

  setPage = (page) => {
    let pager = this.state.pager;
    let size = this.state.size;

    pager.pageSize = this.state.size;

    // get new pager object for specified page
    this.pager = this.getPager(this.props.items, page, size);

    // get new page of items from items array
    //BUGGY CODE FROM EASYDEV: const pageOfItems = items.slicep(ager.startIndex, pager.endIndex + 1);
    // update state
    this.setState({ pager: this.pager });

    // call change page function in parent component
    this.props.onChangePage(this.pager);
  };

  getPager = (totalItems, currentPage, pageSize) => {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // Whoopsie, user navigated to an invalid page, time to reel them back in.
    if (currentPage > totalPages) {
      currentPage = 1;
    }

    let startPage, endPage;
    if (totalPages <= 5) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = range(startPage, endPage + 1);

    // return object with all pager properties required by the view
    return {
      totalItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages,
    };
  };

  onChangePageSize = (event) => {
    this.setState({ size: Number(event.target.value) }, () => this.setPage(1));
  };

  render() {
    const pager = this.pager;

    return (
      <div className="pagination__wrap">
        <div className="flex flex-1 alignItems-center">
          {!pager.pages || pager.pages.length <= 1 ? (
            ''
          ) : (
            <Pagination className="pagination">
              <PaginationItem
                className="pagination__item"
                disabled={pager.currentPage === 1}
              >
                <PaginationLink
                  className="pagination__link pagination__link--arrow"
                  type="button"
                  onClick={() => this.setPage(1)}
                >
                  <ChevronDoubleLeftIcon className="pagination__link-icon" />
                </PaginationLink>
              </PaginationItem>
              {pager.pages.map((page, index) => (
                <PaginationItem
                  className="pagination__item"
                  key={index}
                  active={pager.currentPage === page}
                >
                  <PaginationLink
                    className="pagination__link"
                    type="button"
                    onClick={() => this.setPage(page)}
                  >
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ))}
              <PaginationItem
                className="pagination__item"
                disabled={pager.currentPage === pager.totalPages}
              >
                <PaginationLink
                  className="pagination__link pagination__link--arrow"
                  type="button"
                  onClick={() => this.setPage(pager.totalPages)}
                >
                  <ChevronDoubleRightIcon className="pagination__link-icon" />
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          )}
          <p>
            {p('shows')} {pager.startIndex + 1} {p('to')} {pager.endIndex + 1}{' '}
            {p('of')} {pager.totalItems}
          </p>
        </div>
        <p className="marginRight-8">{p('rowsPerPage')}</p>
        <select value={this.state.pageSize} onChange={this.onChangePageSize}>
          {this.props.rowsPerPage.map((pageSize) => (
            <option key={`Pagination-size-${pageSize}`} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

/* eslint-enable */
