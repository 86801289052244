import t from '../../util/translation/translation';

export const hasCaption = (format, size) =>
  format !== 'TEXT_BANNER' &&
  format !== 'BANNER' &&
  format !== 'CIRCLE' &&
  size !== 'FULL_SCREEN' &&
  !(size === 'SMALL' && (format === 'PORTRAIT' || format === 'SQUARE'));

export const FormatRatios = (format, size) => {
  switch (format) {
    case 'SQUARE':
    case 'CIRCLE':
      return '1:1';
    case 'LANDSCAPE':
      return '16:9';
    case 'PORTRAIT':
      return '9:16';
    case 'HALF_PORTRAIT':
      return '4:5';
    case 'BANNER':
      switch (size) {
        case 'SMALL':
          return '75:8';
        case 'MEDIUM':
          return '75:16';
        case 'LARGE':
          return '5:2';
        default:
          return '5:2';
      }
    case 'TEXT_BANNER':
      return '10:1';
    default:
      return null;
  }
};

export const SPOTLIGHT_FORMATS = [
  'SQUARE',
  'PORTRAIT',
  'CIRCLE',
  'LANDSCAPE',
  'TEXT_BANNER',
  'BANNER',
];
export const SPOTLIGHT_SIZES = ['SMALL', 'MEDIUM', 'LARGE', 'FULL_SCREEN'];
export const getSpotlightFormats = (size) => {
  const FORMATS = ['SQUARE', 'PORTRAIT', 'CIRCLE', 'LANDSCAPE', 'BANNER'];

  switch (size) {
    case 'FULL_SCREEN':
      return ['SQUARE', 'LANDSCAPE', 'TEXT_BANNER'];
    case 'SMALL':
      return FORMATS;
    case 'MEDIUM':
      return FORMATS;
    case 'LARGE':
      return FORMATS;
    default:
      return SPOTLIGHT_FORMATS;
  }
};
export const getReadableFormat = (format) => {
  const formats = SPOTLIGHT_FORMATS.reduce(
    (tot, curr) => ({ ...tot, [curr]: t(`HighlightBannerPage.${curr}`) }),
    {}
  );
  return formats[format] ?? format;
};
export const getFormatDimensions = (format, toString = false, size) => {
  switch (format) {
    case 'LANDSCAPE':
      return toString
        ? t('HighlightBannerPage.imageSizesLandscape')
        : { width: 16, height: 9 };
    case 'SQUARE':
      return toString
        ? t('HighlightBannerPage.imageSizesSquare')
        : { width: 1, height: 1 };
    case 'PORTRAIT':
      return toString
        ? t('HighlightBannerPage.imageSizesPortrait')
        : { width: 9, height: 16 };
    case 'HALF_PORTRAIT':
      return toString ? '4:5' : { width: 4, height: 5 };
    case 'CIRCLE':
      return toString
        ? t('HighlightBannerPage.imageSizesCircle')
        : { width: 1, height: 1 };
    case 'TEXT_BANNER':
      return toString ? '' : { width: 10, height: 1 };
    case 'BANNER':
      switch (size) {
        case 'SMALL':
          return toString ? '75:8  1125x120 px' : { width: 375, height: 40 };
        case 'MEDIUM':
          return toString ? '75:16 1125x240 px' : { width: 375, height: 80 };
        case 'LARGE':
          return toString ? '5:2 1125x450 px' : { width: 375, height: 150 };
        default:
          return toString ? '10:1' : { width: 10, height: 1 };
      }
    default:
      return null;
  }
};
export const getReadableSize = (size) => {
  const sizes = SPOTLIGHT_SIZES.reduce(
    (tot, curr) => ({ ...tot, [curr]: t(`HighlightBannerPage.${curr}`) }),
    {}
  );
  return sizes[size] ?? size;
};
export const isFilterable = (type) =>
  !['EXTERNAL_LINK', 'YOUTUBE_VIDEO'].includes(type);
export const hasAccess = (type) =>
  ![
    'EXTERNAL_LINK',
    'YOUTUBE_VIDEO',
    'COMPANIES',
    'NEWS_CATEGORY',
    'PLAYERS',
    'TEAMS',
  ].includes(type);

export const getIconAmountForSizeFormat = (size, format) => {
  const getElemenets = () => {
    switch (format) {
      case 'LANDSCAPE':
        return {
          SMALL: 3,
          MEDIUM: 2,
          LARGE: 1,
          FULL_SCREEN: 1,
        };
      case 'SQUARE':
        return {
          SMALL: 4,
          MEDIUM: 3,
          LARGE: 2,
          FULL_SCREEN: 1,
        };
      case 'CIRCLE':
        return { SMALL: 4, MEDIUM: 3, LARGE: 1 };
      case 'PORTRAIT':
        return {
          SMALL: 4,
          MEDIUM: 3,
          LARGE: 3,
          FULL_SCREEN: 1,
        };
      case 'BANNER':
        return {
          SMALL: 1,
          MEDIUM: 1,
          LARGE: 1,
          FULL_SCREEN: 1,
        };
      case 'TEXT_BANNER':
        return { FULL_SCREEN: 1 };
      default:
        return null;
    }
  };
  return getElemenets()[size];
};
