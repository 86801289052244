import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Row, Col, Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { partial } from '../../util/translation/translation';
import renderSelectField from '../../shared/components/form/Select';
import validateWebsiteComponentForm from './validateWebsiteComponentForm';

const WebsiteComponentForm = ({
  component,
  handleSubmit,
  initialize,
  onClose,
  itemName,
  availableEntities,
}) => {
  const s = partial('shared');

  const entities = availableEntities[component.id.split('specific-')[1]];

  useEffect(() => {
    const init = async () => {
      if (component?.value) {
        initialize({
          selectedEntity: entities.find(
            (item) => item.value === component.value
          ),
        });
      } else {
        initialize({
          selectedEntity: null,
        });
      }
    };
    init();
  }, [component]);

  return (
    <form className="form" onSubmit={handleSubmit}>
      <Container>
        <Row>
          <Col>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <Field
                  name="selectedEntity"
                  placeholder={`${s('select')} ${itemName}`}
                  options={entities}
                  component={renderSelectField}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Button type="submit" color="primary">
              {component?.id ? s('update') : s('create')}
            </Button>
            {onClose ? (
              <Button type="button" color="secondary" onClick={onClose}>
                {s('close')}
              </Button>
            ) : (
              <Link to="/questionnaires?tab=2">
                {' '}
                <Button type="submit" color="secondary">
                  {s('close')}
                </Button>
              </Link>
            )}
          </Col>
        </Row>
      </Container>
    </form>
  );
};

const reduxF = reduxForm({
  form: 'layout_component_form',
  validate: validateWebsiteComponentForm,
});

export default reduxF(WebsiteComponentForm);
