import React, { useEffect, useState } from 'react';
import { Button, Col, Label } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import renderField from '../../../shared/components/custom/Field';
import renderSelectField from '../../../shared/components/form/Select';
import renderDropZoneFieldNew from '../../../shared/components/form/DropZoneNew';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import renderSwitchField from '../../../shared/components/custom/Switch';
import Api from '../../../util/api';
import Loading from '../../../shared/components/custom/Loading';

const LeagueForm = ({ handleSubmit, initialize, league, seasons }) => {
  const [loading, setLoading] = useState(false);
  const { leagueId } = useParams();
  useEffect(() => {
    if (league) {
      const foundSeason = seasons.find((s) => s.id === league.season?.id);
      const { matches, teams, ...rest } = league;
      initialize({
        ...rest,
        season: foundSeason
          ? {
              label: `${foundSeason.type} ${foundSeason.year}`,
              value: foundSeason,
            }
          : undefined,
        imageUrl: league.imageUrl
          ? [{ src: league.imageUrl, value: league.imageUrl }]
          : undefined,
      });
    }
  }, [league]);

  const handleUpdateRoster = async () => {
    try {
      setLoading(true);
      await Api.clubadmin.leagues.updateRoster(leagueId);
      toast.success('Roster Updated');
      setLoading(false);
    } catch (e) {
      toast.error('Error Updating Roster');
      setLoading(false);
    }
  };
  return (
    <>
      <form className="form" onSubmit={handleSubmit}>
        <Loading loading={loading} />
        <Col md={6}>
          <div className="form__form-group">
            <Label htmlFor="season">Season</Label>
            <Field
              name="season"
              component={renderSelectField}
              options={seasons.map((s) => ({
                label: `${s.type} ${s.year}`,
                value: s,
              }))}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="form__form-group">
            <span>Name</span>
            <Field name="name" component={renderField} type="text" />
          </div>
        </Col>
        <Col md={6}>
          <div className="form__form-group">
            <Label htmlFor="provider">Provider</Label>
            <Field name="provider" component={renderField} type="" />
          </div>
        </Col>
        <Col md={3}>
          <div className="form__form-group">
            <div>
              <Label htmlFor="hasStandings">Has standings</Label>
            </div>
            <Field
              name="hasStandings"
              label={['Yes', 'No']}
              component={renderSwitchField}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="form__form-group">
            <div>
              <Label htmlFor="gender">Gender</Label>
            </div>
            <Field
              name="gender"
              component={renderRadioButtonField}
              radioValue="male"
              label="Male"
              defaultChecked
            />
            <Field
              name="gender"
              label="Female"
              component={renderRadioButtonField}
              radioValue="female"
            />
          </div>
        </Col>
        <Col md={3}>
          <div className="form__form-group">
            <Label htmlFor="sport">Sport</Label>
            <Field name="sport" component={renderField} type="" />
          </div>
        </Col>
        <Col md={3}>
          <div className="form__form-group">
            <div>
              <Label htmlFor="isPlayoff">Playoff</Label>
            </div>
            <Field
              name="isPlayoff"
              label={['Yes', 'No']}
              component={renderSwitchField}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="form__form-group">
            <Label htmlFor="extraData.providerName">Provider Name</Label>
            <Field
              name="extraData.providerName"
              component={renderField}
              type=""
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="form__form-group">
            <Label htmlFor="imageUrl">League logo</Label>
            <Field name="imageUrl" component={renderDropZoneFieldNew} type="" />
          </div>
        </Col>
        <Col md={6}>
          <div className="form__form-group">
            <Label htmlFor="noPlayerImageUrl">Player avatar</Label>
            <Field
              name="noPlayerImageUrl"
              component={renderDropZoneFieldNew}
              type=""
            />
          </div>
        </Col>
        <Col md={12}>
          <Button type="submit" color="info">Submit</Button>
          <Link to="/clubadmin?tab=4"><Button color="secondary">Close</Button></Link>
          <Button className="mx-3" onClick={handleUpdateRoster} color="secondary" disabled={!leagueId}>Update roster</Button>
        </Col>
      </form>
    </>
  );
};

const rf = reduxForm({
  form: 'leagueAdminForm',
});

export default rf(LeagueForm);
