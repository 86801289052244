import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Close from 'mdi-react/CloseCircleOutlineIcon';
import WebsiteComponentForm from './WebsiteComponentForm';
import t, { partial } from '../../util/translation/translation';

const CustomWebsiteComponent = ({
  showModal,
  onClose,
  selectedComponent,
  onSave,
  availableEntities,
}) => {
  const compName = selectedComponent?.id?.split('specific-')[1];
  if (!compName) {
    return null;
  }
  const s = partial('shared');

  const promptTitle = `${selectedComponent?.value ? s('edit') : s('create')} ${t(`appLayout.${compName}`)}`;
  const handleSubmit = (values) => {
    const payload = {
      ...selectedComponent,
      value: values.selectedEntity.value,
      label: values.selectedEntity.label,
    };
    onSave(payload);
  };
  return (
    <Modal
      modalClassName="question-modal"
      isOpen={!!showModal}
      toggle={onClose}
    >
      <ModalHeader className="navbar-modal-header">
        <div className="modal-header-title">{promptTitle}</div>
        <div className="float-right">
          <button className="marginBottom-0 builder-button" onClick={onClose}>
            <Close />
          </button>
        </div>
      </ModalHeader>
      <ModalBody>
        <WebsiteComponentForm
          availableEntities={availableEntities}
          itemName={t(`appLayout.${compName}`)}
          type={selectedComponent?.value ? 'edit' : 'create'}
          component={selectedComponent}
          onSubmit={handleSubmit}
          onClose={onClose}
        />
      </ModalBody>
    </Modal>
  );
};
export default CustomWebsiteComponent;
