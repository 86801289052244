import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { gql, GraphQLClient } from 'graphql-request';
import translate, { partial } from './translation/translation';
import { firebaseConfig } from '../containers/_app/FirebaseConfig';
import Config from '../config';

// const LOCAL_SERVICES = ['communication', 'user-term'];
const LOCAL_SERVICES = [];

export const getClubName = () => {
  const path = window.location.pathname.split('/')[1];
  if (path === 'stj') {
    return 'stjernen';
  }
  return path;
};
const API_BASE_URL = `https://${getClubName()}.api${getClubName() === 'tfg' ? '-dev' : ''}.fangroup.io/api`;
const IDENTITY_TOOLKIT_URL = 'https://identitytoolkit.googleapis.com/v1';
const IDENTITY_RELYING_PARTY_URL =
  'https://www.googleapis.com/identitytoolkit/v3/relyingparty';
export const getServiceUrl = (service) => {
  if (
    window.location.href.includes('localhost') &&
    LOCAL_SERVICES.includes(service)
  ) {
    switch (service) {
      case 'content':
        return 'http://localhost:3021';
      case 'app-layout':
        return 'http://localhost:3024';
      case 'spotlight':
        return 'http://localhost:3023';
      case 'communication':
        return 'http://localhost:3020';
      case 'stats-new':
        return 'http://localhost:3026';
      case 'user-term':
        return 'http://localhost:3008';
      case 'company':
        return 'http://localhost:3012';
      default:
        return null;
    }
  }
  return `https://${service}.api${getClubName() === 'tfg' ? '-dev' : ''}.fangroup.io${service === 'user' ? '/api' : ''}`;
};

const FEP_ALLOW_URLS = [
  'club',
  'company',
  'events',
  'authorization',
  'matchinfo',
  'user-term',
  'image',
  'communication',
  'lottery',
  'content',
  'spotlight',
  'stats-new',
  'auctions',
  'user',
  'ticket',
  'question',
].map(getServiceUrl);

const p = partial('api');

const allowedServices = ['question'];
const allowedLocalPorts = ['3021'];

const generateServerExceptionMessage = (response) => {
  const isLocal = response?.config?.url?.includes('localhost');
  let serviceFound = false;
  if (isLocal) {
    serviceFound = allowedLocalPorts.some((port) =>
      response?.config?.url?.includes(`localhost:${port}`)
    );
  } else {
    serviceFound = allowedServices.some((service) =>
      response?.config?.url?.includes(`https://${service}`)
    );
  }
  const message = response?.data?.message;
  if (serviceFound && message !== undefined && message !== '') {
    throw translate(message);
  }
};

export default class Api {
  static async axios(
    method,
    url,
    auth = true,
    _payload = { header: {} },
    containsFiles = false,
    writeHeaders = true
  ) {
    let response;
    let restPayload;
    const { header, ...remPayload } = _payload;
    restPayload = remPayload;
    if (Array.isArray(_payload)) {
      restPayload = [..._payload];
    }
    const headers = header || {};
    const club = getClubName();
    if (
      FEP_ALLOW_URLS.some((allowUrl) => url.includes(allowUrl)) &&
      writeHeaders
    ) {
      headers['fep-context-name'] =
        `${localStorage.getItem(`app_name-${club}`)}`;
      headers['fep-context'] = `${localStorage.getItem(`ntb_team_id-${club}`)}`;
    }
    if (auth) {
      const firebaseAuth = getAuth();
      try {
        const FIRToken = await firebaseAuth.currentUser.getIdToken();
        headers.Authorization = `Bearer ${FIRToken}`;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }

    try {
      if (url.includes(API_BASE_URL) && Config.monolithEnabled === false) {
        alert(`Calling monolith endpoint ${url}`);
        // throw new Error('Monolith disabled');
      }
      response = await axios({
        url,
        method,
        data:
          url.includes(getServiceUrl('image')) || containsFiles
            ? _payload
            : restPayload,
        headers,
        params:
          method.toLowerCase() === 'get' &&
          Object.values(restPayload).length > 0 &&
          restPayload,
      });
    } catch (err) {
      response = {
        ...err.response,
      };
    }
    if (![200, 201].includes(response.status)) {
      generateServerExceptionMessage(response);
      if (
        response.status === 500 ||
        (response?.headers?.['content-type'] ?? '').startsWith('text/html')
      ) {
        throw p('serverOrInternetERROR');
      }
      if (![417, 400, 412].includes(response.status)) {
        let e;
        try {
          e = p(response.data.message);
        } catch {
          e = p(response.status);
        }
        throw e;
      }
      if (response.data.error) {
        if (response.data.error instanceof Array) {
          throw response.data.error.join('\n');
        } else {
          let err = response.data.error;
          if (err.errors) {
            throw err;
          }
          switch (
            err // Translate common errors / exceptions.
          ) {
            case 'token_expired': {
              err = p('expiredLogin');
              break;
            }
            case 'token_invalid': {
              err = p('invalidLogin');
              break;
            }
            case 'token_not_provided': {
              err = p('notLoggedIn');
              break;
            }
            case 'user_not_found':
              err = p('noUserFound');
              break;
            default: {
              err = p('serverOrInternetERROR');
              break;
            }
          }

          throw err;
        }
      } else {
        throw response;
      }
    }
    return response;
  }
  static async getStreamableData(url, onDataReceived, auth = true) {
    const headers = {};
    const club = getClubName();
    if (FEP_ALLOW_URLS.some((allowUrl) => url.includes(allowUrl))) {
      headers['fep-context-name'] =
        `${localStorage.getItem(`app_name-${club}`)}`;
      headers['fep-context'] = `${localStorage.getItem(`ntb_team_id-${club}`)}`;
    }
    if (auth) {
      const firebaseAuth = getAuth();
      try {
        const FIRToken = await firebaseAuth.currentUser.getIdToken();
        headers.Authorization = `Bearer ${FIRToken}`;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');

      let buffer = '';
      let done = false;

      while (!done) {
        // eslint-disable-next-line no-await-in-loop
        const { value, done: readerDone } = await reader.read();
        done = readerDone;

        buffer += decoder.decode(value || new Uint8Array(), { stream: !done });
        let boundary = buffer.indexOf('\n');

        while (boundary !== -1) {
          const completeChunk = buffer.slice(0, boundary);
          buffer = buffer.slice(boundary + 1);

          if (completeChunk) {
            try {
              const decodedChunk = JSON.parse(completeChunk);
              if (onDataReceived && typeof onDataReceived === 'function') {
                onDataReceived(decodedChunk); // Update the state with the new chunk
              }
            } catch (error) {
              console.error('Error parsing JSON:', error, completeChunk);
            }
          }

          boundary = buffer.indexOf('\n');
        }
      }

      // Handle any remaining data in the buffer
      if (buffer.length > 0) {
        try {
          const decodedChunk = JSON.parse(buffer);
          if (onDataReceived && typeof onDataReceived === 'function') {
            onDataReceived(decodedChunk); // Update the state with the new chunk
          }
        } catch (error) {
          console.error('Error parsing JSON:', error, buffer);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
  static getGraphQLClient = async (type = 'user', authenticate = true) => {
    const headers = {};
    if (authenticate) {
      const firebaseAuth = getAuth();
      const FIRToken = await firebaseAuth.currentUser.getIdToken();
      headers.Authorization = `Bearer ${FIRToken}`;
      const club = getClubName();
      if (type === 'user-term') {
        headers['fep-context-name'] =
          `${localStorage.getItem(`app_name-${club}`)}`;
        headers['fep-context'] =
          `${localStorage.getItem(`ntb_team_id-${club}`)}`;
      }
    }
    return new GraphQLClient(`${getServiceUrl(type)}/graphql`, {
      headers,
    });
  };
  static crud = (entity, _path = '', auth = true) => {
    const snakeCase = entity.replace(/([A-Z])/, '_$1').toLowerCase();
    return {
      all: () =>
        Api.axios(
          'get',
          `${API_BASE_URL}/admin/${_path !== '' && !_path.endsWith('/') ? `${_path}/` : _path}${snakeCase}`
        ),
      show: (id) =>
        Api.axios(
          'get',
          `${API_BASE_URL}/admin/${_path !== '' && !_path.endsWith('/') ? `${_path}/` : _path}${snakeCase}/${id}`
        ),
      delete: (id) =>
        Api.axios(
          'delete',
          `${API_BASE_URL}/admin/${_path !== '' && !_path.endsWith('/') ? `${_path}/` : _path}${snakeCase}/${id}`
        ),
      create: (payload) =>
        Api.axios(
          'post',
          `${API_BASE_URL}/admin/${_path !== '' && !_path.endsWith('/') ? `${_path}/` : _path}${snakeCase}`,
          auth,
          payload
        ),
      update: (payload, id) =>
        Api.axios(
          'put',
          `${API_BASE_URL}/admin/${_path !== '' && !_path.endsWith('/') ? `${_path}/` : _path}${snakeCase}/${id}`,
          auth,
          payload
        ),
    };
  };

  static login = {
    me: () => Api.axios('get', `${getServiceUrl('authorization')}/getMe`),
    doesUserExist: ({ phone, email }) => {
      const queryString = phone
        ? `phoneNumber=${encodeURIComponent(phone)}`
        : `email=${encodeURIComponent(email)}`;
      return Api.axios(
        'get',
        `${getServiceUrl('user')}/doesUserExist?${queryString}`,
        false
      );
    },
  };
  static news = {
    getNews: ({ page, take }, { orderBy, direction }, searchText) =>
      Api.axios(
        'get',
        `${getServiceUrl('content')}/admin/content?page=${page}&take=${take}&orderBy=${orderBy}&direction=${direction}${searchText ? `&search=${searchText}` : ''}`
      ).then(({ data }) => data),
    deleteNews: (id) =>
      Api.axios('delete', `${getServiceUrl('content')}/admin/content/${id}`),
    getApiAccessList: () =>
      Api.axios('get', `${getServiceUrl('content')}/admin/api/access`).then(
        ({ data }) => data
      ),
    createApiAccess: (payload) =>
      Api.axios(
        'post',
        `${getServiceUrl('content')}/admin/api/access`,
        true,
        payload
      ),
    deleteApiAccess: (id) =>
      Api.axios('delete', `${getServiceUrl('content')}/admin/api/access/${id}`),
    getNewsById: (id) =>
      Api.axios('get', `${getServiceUrl('content')}/admin/content/${id}`).then(
        ({ data }) => data
      ),
    createNews: (payload) =>
      Api.axios(
        'post',
        `${getServiceUrl('content')}/admin/content`,
        true,
        payload
      ).then(({ data }) => data),
    updateNews: (newsID, payload) =>
      Api.axios(
        'put',
        `${getServiceUrl('content')}/admin/content/${newsID}`,
        true,
        payload
      ),
    getNewsAccess: (id) =>
      Api.axios('get', `${getServiceUrl('content')}/admin/access/${id}`).then(
        ({ data }) => data
      ),
    updateNewsAccess: (id, payload) =>
      Api.axios(
        'put',
        `${getServiceUrl('content')}/admin/access/${id}`,
        true,
        payload
      ).then(({ data }) => data),
    getNewsViews: (payload) =>
      Api.axios(
        'get',
        `${getServiceUrl('stats-new')}/news/getNewsViews`,
        true,
        payload
      ),
    contactNews: async (newsId, payload) =>
      Api.axios(
        'POST',
        `${getServiceUrl('content')}/admin/communication/${newsId}`,
        true,
        payload
      ),
    updateScheduledMessage: async (newsId, messageId, payload) => {
      return Api.axios(
        'PUT',
        `${getServiceUrl('content')}/admin/communication/${newsId}/${messageId}`,
        true,
        payload
      );
    },
    deleteScheduledMessage: async (messageId) => {
      return Api.axios(
        'DELETE',
        `${getServiceUrl('content')}/admin/communication/${messageId}`,
        true
      );
    },
    getNewsCategories: () =>
      Api.axios('get', `${getServiceUrl('content')}/category`).then(
        ({ data }) => data
      ),
    getCategoryById: (id) =>
      Api.axios('get', `${getServiceUrl('content')}/admin/category/${id}`).then(
        ({ data }) => data
      ),
    removeCategory: (id) =>
      Api.axios('delete', `${getServiceUrl('content')}/admin/category/${id}`),
    createCategory: (payload) =>
      Api.axios(
        'post',
        `${getServiceUrl('content')}/admin/category`,
        true,
        payload
      ),
    updateCategory: (id, payload) =>
      Api.axios(
        'put',
        `${getServiceUrl('content')}/admin/category/${id}`,
        true,
        payload
      ),
    updateCategoryOrder: (payload) =>
      Api.axios(
        'put',
        `${getServiceUrl('content')}/admin/category/order`,
        true,
        payload
      ).then(({ data }) => data),
    getNewsProviders: () =>
      Api.axios('get', `${getServiceUrl('content')}/provider/providers`).then(
        ({ data }) => data
      ),
    updateProviderCategory: (providerId, payload) =>
      Api.axios(
        'post',
        `${getServiceUrl('content')}/provider/providers/${providerId}`,
        true,
        payload
      ).then(({ data }) => data),
    getNewsLikes: (newsId) =>
      Api.axios(
        'get',
        `${getServiceUrl('content')}/admin/engagement/likes/${newsId}`
      ).then(({ data }) => data),
    refreshNews: () =>
      Api.axios('post', `${getServiceUrl('content')}/provider/refresh`, true),
  };

  static tiers = {
    allTiersWithDefault: (config) =>
      Api.axios(
        'get',
        `${getServiceUrl('tiers')}/tier/getTiersWithDefault/${config?.ntb_team_id}`,
        true,
        {},
        true
      ),
    getAllTiersWithUsers: (config) =>
      Api.axios(
        'get',
        `${getServiceUrl('tiers')}/tier/getAllTiersWithUsers/${config?.ntb_team_id}`,
        true,
        {},
        true
      ),
    allTiers: (config) =>
      Api.axios(
        'get',
        `${getServiceUrl('tiers')}/tier/getTiers/${config?.ntb_team_id}`,
        true,
        {},
        true
      ),
    createTier: (payload, config) =>
      Api.axios(
        'post',
        `${getServiceUrl('tiers')}/tier/createTier/${config?.ntb_team_id}`,
        true,
        payload,
        true
      ),
    editTier: (id, payload, config) =>
      Api.axios(
        'put',
        `${getServiceUrl('tiers')}/tier/editTier/${config?.ntb_team_id}/${id}`,
        true,
        payload,
        true
      ),
    getTier: (id, config) =>
      Api.axios(
        'get',
        `${getServiceUrl('tiers')}/tier/getTier/${config?.ntb_team_id}/${id}`,
        true,
        {},
        true
      ),
    deleteTier: (id, config) =>
      Api.axios(
        'delete',
        `${getServiceUrl('tiers')}/tier/removeTier/${config?.ntb_team_id}/${id}`,
        true,
        {},
        true
      ),
    getTierFeatures: (config) =>
      Api.axios(
        'get',
        `${getServiceUrl('tiers')}/tierFeature/getTierFeatures/${config?.ntb_team_id}`,
        true,
        {},
        true
      ),
    userTiersByCloudIDs: (userCloudIds, config) =>
      Api.axios(
        'get',
        `${getServiceUrl('tiers')}/tier/userTiersByCloudIDs/${config?.ntb_team_id}`,
        true,
        {
          userCloudIds,
        },
        true
      ),
    getTierUsers: (id, page, count, reverse, config) =>
      Api.axios(
        'get',
        `${getServiceUrl('tiers')}/tier/getTierUsers/${config?.ntb_team_id}/${id}`,
        true,
        { page, count, reverse },
        true
      ),
    getUserAffiliations: (cloudId, config) =>
      Api.axios(
        'get',
        `${getServiceUrl('tiers')}/tier/getAdminUserAffiliations/${config?.ntb_team_id}?cloudId=${cloudId}`,
        true,
        {},
        true
      ),
    assignUserToTier: (payload, config) =>
      Api.axios(
        'post',
        `${getServiceUrl('tiers')}/tier/assignUserToTier/${config?.ntb_team_id}`,
        true,
        payload,
        true
      ),
    deleteUserAffiliation: (id, config) =>
      Api.axios(
        'delete',
        `${getServiceUrl('tiers')}/tier/removeUserAffiliationAdmin/${config?.ntb_team_id}/${id}`,
        true,
        {},
        true
      ),
    updateTierOrder: (payload, config) =>
      Api.axios(
        'post',
        `${getServiceUrl('tiers')}/tier/updateTierOrder/${config?.ntb_team_id}`,
        true,
        payload,
        true
      ),
    getAllTierTransactions: (config) =>
      Api.axios(
        'get',
        `${getServiceUrl('tiers')}/payment/getAllTierTransactions/${config?.ntb_team_id}`,
        true,
        {},
        true
      ),
    assignUsersFromCSV: (payload, config) =>
      Api.axios(
        'post',
        `${getServiceUrl('tiers')}/tier/assignUsersFromCSV/${config?.ntb_team_id}`,
        true,
        payload,
        true
      ),
    getTiersWithUserCount: (config) =>
      Api.axios(
        'GET',
        `${getServiceUrl('tiers')}/tier/getTiersWithUserCount/${config?.ntb_team_id}`,
        true
      ),
  };
  static players = {
    createPlayer: (payload) =>
      Api.axios('POST', `${getServiceUrl('matchinfo')}/player/`, true, {
        header: { 'fep-context-name': getClubName() },
        ...payload,
      }),
    getPlayer: (playerID) =>
      Api.axios(
        'get',
        `${getServiceUrl('matchinfo')}/player/player/${playerID}`
      ),
    updatePlayer: (payload) =>
      Api.axios('put', `${getServiceUrl('matchinfo')}/player/`, true, {
        header: { 'fep-context-name': getClubName() },
        ...payload,
      }),
    deletePlayer: (playerID) =>
      Api.axios('delete', `${getServiceUrl('matchinfo')}/player/${playerID}`),
    getSponsors: () =>
      Api.axios('get', `${getServiceUrl('company')}/clubRelation/sponsors`),
    getPLayersFromNtb: (teamID) =>
      Api.axios('get', `${getServiceUrl('matchinfo')}/player/${teamID}/`),
    search: (query, teamId) =>
      Api.axios(
        'GET',
        `${getServiceUrl('matchinfo')}/player/search?q=${query}&teamId=${teamId}`
      ),
    transfer: (teamId, playerId) =>
      Api.axios(
        'PUT',
        `${getServiceUrl('matchinfo')}/player/transfer/${teamId}/${playerId}`
      ),
    all: (teamId) =>
      Api.axios('GET', `${getServiceUrl('matchinfo')}/player/${teamId}`),
    setCompanyOnRating: (payload) =>
      Api.axios(
        'POST',
        `${getServiceUrl('matchinfo')}/player/companyRating`,
        true,
        payload
      ),
    getCompanyOnRating: (teamId, matchId) =>
      Api.axios(
        'GET',
        `${getServiceUrl('matchinfo')}/player/companyRating/${teamId}/${matchId}`,
        true
      ),
  };

  static users = {
    getUser: async (userCloudId, clubCloudIdName) => {
      const firebaseAuth = getAuth();
      const FIRToken = await firebaseAuth.currentUser.getIdToken();
      const graphQLClient = new GraphQLClient(
        `${getServiceUrl('user')}/graphql`,
        {
          headers: {
            Authorization: `Bearer ${FIRToken}`,
          },
        }
      );
      const query = gql`
        query GetTeamUser($userCloudId: String!, $club: String!) {
          users(
            take: 1
            where: {
              userCloudId: { equals: $userCloudId }
              teams: {
                some: {
                  toBeDeletedAt: { equals: null }
                  team: { name: { equals: $club } }
                }
              }
            }
          ) {
            id
            userCloudId
            firstname
            lastname
            flag
            countryCode
            zip
            gender
            dateOfBirth
            username
            phoneNumber
            email
            teams(
              where: {
                team: { name: { equals: $club } }
                toBeDeletedAt: { equals: null }
              }
            ) {
              id
              roles {
                id
                name
              }
            }
          }
        }
      `;
      const { users } = await graphQLClient.request(query, {
        userCloudId,
        club: clubCloudIdName,
      });

      if (users.length !== 1) {
        throw new Error('Could not find the user');
      }
      return users?.[0];
    },
    createUser: (payload) =>
      Api.axios('post', `${getServiceUrl('user')}/createUser`, true, payload),
    getUsersByCloudIds: async (cloudIds) => {
      return Api.axios('post', `${getServiceUrl('user')}/byCloudIds`, true, {
        cloudIds,
      });
    },
    getTeamUsers: async (
      page = 1,
      pageSize = 100,
      orderObject = { firstname: 'asc' },
      searchQuery = ''
    ) => {
      const orderBy = Object.keys(orderObject)[0];
      const order = orderObject[orderBy];
      const club = getClubName();
      const clubName = `${localStorage.getItem(`app_name-${club}`)}`;
      const {
        data: { total, results },
      } = await Api.axios(
        'get',
        `${getServiceUrl('user')}/searchUsers?order=${order}&take=${pageSize}&skip=${(page - 1) * pageSize}&orderBy=${orderBy}&searchQuery=${searchQuery || ''}&club=${clubName}`
      );
      return {
        total,
        users: results.map((item) => ({ ...item, username: item.phoneNumber })),
        page,
        pageSize,
        club,
      };
    },
    deleteTeamUser: async (cloudIdTeamName, userId, deleteComment) => {
      const graphQLClient = await this.getGraphQLClient();
      const query = gql`
        mutation DeleteTeamuser(
          $team: String!
          $userId: String!
          $toBeDeletedAt: DateTime
          $deleteComment: String
        ) {
          softDeleteTeamUser(
            team: $team
            userId: $userId
            toBeDeletedAt: $toBeDeletedAt
            deleteComment: $deleteComment
          )
        }
      `;
      const data = await graphQLClient.request(query, {
        team: cloudIdTeamName,
        userId,
        toBeDeletedAt: new Date(),
        deleteComment,
      });
      return data;
    },
    getUsersByRoleName: async (roleName, config) => {
      // TODO ADD CONFIG TO METHOD
      const graphQLClient = await this.getGraphQLClient();
      const query = gql`
        query GetUsersWithRole(
          $roleName: TeamRoleNameType!
          $teamName: String!
        ) {
          teamUsers(
            where: {
              toBeDeletedAt: { equals: null }
              team: { name: { equals: $teamName } }
              roles: { some: { name: { equals: $roleName } } }
            }
          ) {
            id
            user {
              userCloudId
              firstname
              lastname
              id
              username
            }
          }
        }
      `;
      const { teamUsers } = await graphQLClient.request(query, {
        teamName: config?.cloudIdName,
        roleName,
      });
      const mappedUsers = teamUsers.map(({ user, id }) => ({
        ...user,
        teamUserId: id,
      }));
      return mappedUsers;
    },
    getAvailableRoles: async () => {
      const graphQLClient = await this.getGraphQLClient();
      const query = gql`
        query {
          teamRoles(where: { name: { notIn: [] } }) {
            id
            name
          }
        }
      `;
      const { teamRoles: availableRoles } = await graphQLClient.request(query);
      return availableRoles;
    },
    userSearch: async (condition) => {
      const firebaseAuth = getAuth();
      const FIRToken = await firebaseAuth.currentUser.getIdToken();
      const graphQLClient = new GraphQLClient(
        `${getServiceUrl('user')}/graphql`,
        {
          headers: {
            Authorization: `Bearer ${FIRToken}`,
          },
        }
      );
      const query = gql`
        query SearchUser($whereClause: UserWhereUniqueInput!) {
          user(where: $whereClause) {
            userCloudId
            firstname
            lastname
            email
            phoneNumber
          }
        }
      `;
      try {
        const { user } = await graphQLClient.request(query, {
          whereClause: {
            ...condition,
          },
        });
        return user;
      } catch (err) {
        throw p('serverOrInternetERROR');
      }
    },
    updateUserGraphQL: async (userServiceId, data) => {
      const graphQLClient = await this.getGraphQLClient();
      const mutation = gql`
        mutation UpdateUser(
          $where: UserWhereUniqueInput!
          $data: UserUpdateInput!
        ) {
          updateUser(where: $where, data: $data) {
            username
          }
        }
      `;
      await graphQLClient.request(mutation, {
        where: {
          id: userServiceId,
        },
        data,
      });
    },
    updateUserRoles: async (teamUserId = '', roleName, enabled) => {
      const graphQLClient = await this.getGraphQLClient();
      const mutation = gql`
        mutation ChangeUserRole(
          $roleName: String!
          $teamUserId: String!
          $connect: Boolean!
        ) {
          changeUserRole(
            roleName: $roleName
            teamUserId: $teamUserId
            connect: $connect
          )
        }
      `;
      await graphQLClient.request(mutation, {
        roleName,
        teamUserId,
        connect: enabled,
      });
    },
    findByPhoneNumbers: async (phoneNumbers) => {
      const mappedUsers = phoneNumbers.map((item) => ({ phoneNumber: item }));
      const { data } = await Api.axios(
        'post',
        `${getServiceUrl('user')}/getUsersByPhoneOrEmail`,
        true,
        { users: mappedUsers }
      );
      return data;
    },
  };
  static companies = {
    getCompanies: (pageSize, page, searchText, order, reverse, searchIn) =>
      Api.axios(
        'get',
        `${getServiceUrl('company')}/company/paginated?page=${page}&take=${pageSize}&reverse=${reverse}&orderBy=${order}&search=${searchText}${searchIn.length > 0 ? `&searchIn=${searchIn}` : ''} `
      ),
    getCompanyByField: (searchText, searchField) =>
      Api.axios(
        'get',
        `${getServiceUrl('company')}/company/getCompanyByField?searchField=${searchField}&searchValue=${searchText}`
      ).then((res) => {
        return res.data;
      }),
    getClubCompanies: (payload) =>
      Api.axios(
        'get',
        `${getServiceUrl('company')}/clubRelation/companiesForClub`,
        true,
        payload
      ).then((res) => {
        return res.data;
      }),
    searchCompanies: (searchWord) =>
      Api.axios(
        'get',
        `${getServiceUrl('company')}/company/search/${searchWord}`
      ).then(({ data }) => {
        const club = getClubName();
        const clubId = localStorage.getItem(`ntb_team_id-${club}`);
        const mappedData = data.map((company) => {
          const foundCompanyName =
            company.clubRelations?.find((it) => it.clubId === clubId)?.name ||
            company.name ||
            '';
          return { ...company, name: foundCompanyName };
        });
        return { data: mappedData };
      }),
    createCompany: (payload) =>
      Api.axios(
        'post',
        `${getServiceUrl('company')}/admin/company`,
        true,
        payload
      ).then((res) => {
        return res.data;
      }),
    editCompany: (payload, companyID) =>
      Api.axios(
        'put',
        `${getServiceUrl('company')}/admin/company/${companyID}`,
        true,
        payload
      ),
    getCompanyCategories: () =>
      Api.axios('get', `${getServiceUrl('company')}/company/companyCategories`),
    deleteCompany: (ID) =>
      Api.axios('delete', `${getServiceUrl('company')}/admin/company/${ID}`),
    unlinkCompany: (payload) =>
      Api.axios(
        'post',
        `${getServiceUrl('company')}/clubRelation/admin/unlink`,
        true,
        payload
      ),
    getCompany: (ID) =>
      Api.axios('get', `${getServiceUrl('company')}/company/${ID}`).then(
        (res) => {
          return res.data;
        }
      ),
    linkCompany: (payload) =>
      Api.axios(
        'post',
        `${getServiceUrl('company')}/clubRelation/admin/link`,
        true,
        payload
      ),
    getCompaniesForClub: () =>
      Api.axios(
        'get',
        `${getServiceUrl('company')}/clubRelation/companiesForClub`
      ).then((res) => {
        return res.data;
      }),
    getCompaniesForGroup: (group) =>
      Api.axios(
        'get',
        `${getServiceUrl('company')}/group/companiesByGroup/${encodeURIComponent(group)}`
      ).then((res) => {
        return res.data;
      }),
    getCompaniesCountForLevels: (levels) =>
      Api.axios(
        'get',
        `${getServiceUrl('company')}/level/companiesCountByLevel?${levels.map((item) => `levels[]=${encodeURIComponent(item.value)}`).join('&')}`
      ).then((res) => {
        return res.data;
      }),
    getCompaniesCountForGroups: (groups) =>
      Api.axios(
        'get',
        `${getServiceUrl('company')}/group/companiesCountByGroup?${groups.map((item) => `groups[]=${encodeURIComponent(item.value)}`).join('&')}`
      ).then((res) => {
        return res.data;
      }),
    addCompaniesEmployeesFromCSV: (companies) => {
      const data = new FormData();
      data.append('companies', companies);
      return Api.axios(
        'post',
        `${getServiceUrl('company')}/admin/company/importFromCSV`,
        true,
        data,
        true
      );
    },
    getAllCompaniesEmployees: () =>
      Api.axios('get', `${getServiceUrl('company')}/employee/admin/getForClub`),
    getCompanyCountByLevel: () =>
      Api.axios(
        'GET',
        `${getServiceUrl('company')}/clubRelation/admin/getCompanyCount`,
        true
      ),
    getDeals: ({ page, take }, { orderBy, direction }) =>
      Api.axios(
        'get',
        `${getServiceUrl('company')}/admin/deal?page=${page}&take=${take}&orderBy=${orderBy}&direction=${direction}`
      ).then(({ data }) => data),
    deleteDeal: (ID) =>
      Api.axios('delete', `${getServiceUrl('company')}/admin/deal/${ID}`),
  };

  static club = {
    createSetting: (payload, clubId = null) =>
      Api.axios(
        'post',
        `${getServiceUrl('club')}/setting`,
        true,
        {
          ...payload,
          ...(clubId ? { header: { 'fep-context': clubId } } : {}),
        },
        false,
        !clubId
      ),
    editSetting: (payload, clubId = null) =>
      Api.axios(
        'put',
        `${getServiceUrl('club')}/setting`,
        true,
        {
          ...payload,
          ...(clubId ? { header: { 'fep-context': clubId } } : {}),
        },
        false,
        !clubId
      ),
    deleteSetting: (settingID) =>
      Api.axios('delete', `${getServiceUrl('club')}/setting/${settingID}`),
    getGroups: () =>
      Api.axios('get', `${getServiceUrl('club')}/setting/Group`).then((res) => {
        return res.data;
      }),
    getLevels: () =>
      Api.axios('get', `${getServiceUrl('club')}/setting/Level`).then((res) => {
        return res.data;
      }),
    getSettings: () =>
      Api.axios('get', `${getServiceUrl('club')}/setting/`).then((res) => {
        return res.data;
      }),
    getSettingsByKey: (key, clubId = null) =>
      Api.axios(
        'get',
        `${getServiceUrl('club')}/setting/${key}`,
        true,
        clubId ? { header: { 'fep-context': clubId } } : {},
        false,
        !clubId
      ),
    getCompaniesForLevel: (level) =>
      Api.axios(
        'get',
        `${getServiceUrl('company')}/level/companiesByLevel/${encodeURIComponent(level)}`
      ).then((res) => {
        return res.data;
      }),
    deleteCompaniesFromGroup: (payload) =>
      Api.axios(
        'delete',
        `${getServiceUrl('company')}/group/admin/removeCompanies`,
        true,
        payload
      ),
    deleteCompaniesFromLevel: (payload) =>
      Api.axios(
        'delete',
        `${getServiceUrl('company')}/level/admin/removeCompanies`,
        true,
        payload
      ),
    addCompaniesToGroup: (payload) =>
      Api.axios(
        'post',
        `${getServiceUrl('company')}/group/admin/addCompanies`,
        true,
        payload
      ),
    addCompaniesToLevel: (payload) =>
      Api.axios(
        'post',
        `${getServiceUrl('company')}/level/admin/addCompanies`,
        true,
        payload
      ),
    updateSettingsOrder: (payload) =>
      Api.axios(
        'put',
        `${getServiceUrl('club')}/setting/order/`,
        true,
        payload
      ),
  };
  static matches = {
    getMatches: (seasonID) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/matches/getFutureAndPast?season_id=${seasonID}`
      ),
    getMatch: (id) => Api.axios('get', `${API_BASE_URL}/admin/matches/${id}`),
    getFutureMatches: (count) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/matches/future_matches?count=${count}`
      ),
    getTeams: () => Api.axios('get', `${API_BASE_URL}/admin/matches/teams`),
    getMatchTypes: () =>
      Api.axios('get', `${API_BASE_URL}/admin/matches/match_types`),
    getAllControlImages: () =>
      Api.axios('get', `${API_BASE_URL}/admin/matches/control_images/present`),
    getControlImage: (id) =>
      Api.axios('get', `${API_BASE_URL}/admin/matches/control_images/${id}`),
    getPriceGroups: (id) =>
      Api.axios('get', `${API_BASE_URL}/admin/matches/${id}/price_groups`),
    getFieldTypes: (id) =>
      Api.axios('get', `${API_BASE_URL}/admin/matches/${id}/field_types`),
    putPriceGroups: (id, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/matches/${id}/price_groups/multiple`,
        true,
        payload
      ),
    putFieldTypes: (id, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/matches/${id}/field_types/multiple`,
        true,
        payload
      ),
    getFieldExclusions: (id) =>
      Api.axios('get', `${API_BASE_URL}/admin/matches/${id}/field_exclusions`),
    putFieldExclusions: (id, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/matches/${id}/field_exclusions`,
        true,
        payload
      ),
    updateMatchStadium: (matchID, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/matches/${matchID}/stadium`,
        true,
        payload
      ),
    putResult: (id, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/matches/${id}/result`,
        true,
        payload
      ),
    createMatch: (payload) =>
      Api.axios('post', `${API_BASE_URL}/admin/matches`, true, payload),
    updateMatch: (id, payload) =>
      Api.axios('put', `${API_BASE_URL}/admin/matches/${id}`, true, payload),
    deleteMatch: (id) =>
      Api.axios('delete', `${API_BASE_URL}/admin/matches/${id}`),
    updateMatchAds: (matchID, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/matches/${matchID}/match_ads`,
        true,
        payload
      ),
    // getSponsors: () => Api.axios('get', `${API_BASE_URL}/admin/matches/contributors`),
    //  New features
    getSponsors: () =>
      Api.axios('get', `${getServiceUrl('company')}/clubRelation/sponsors`),
    getManualVisitors: (matchID, page, pageSize) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/matches/${matchID}/registered_visitors?page=${page}&count=${pageSize}`
      ),
    getSystemVisitors: (matchID, page, pageSize) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/matches/${matchID}/app_visitors?page=${page}&count=${pageSize}`
      ),
    removeAttendance: (matchID, attendanceID) =>
      Api.axios(
        'delete',
        `${API_BASE_URL}/admin/matches/${matchID}/manual_visitors/${attendanceID}`
      ),
    createManualVisitor: (matchID, payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/matches/${matchID}/manual_visitors`,
        true,
        payload
      ),
    exportAttendance: (matchID) =>
      Api.axios('GET', `${API_BASE_URL}/admin/matches/${matchID}/export_users`),
    getNTBTeamStages: (teamId) =>
      Api.axios('GET', `https://v2api.nifs.no/nifteams/${teamId}/nifstages/`),
    getMatchesForStage: (stageId) =>
      Api.axios(
        'GET',
        `https://v2api.nifs.no/nifstages/${stageId}/nifmatches/`
      ),
  };
  static seasonTickets = {
    getFields: () =>
      Api.axios('get', `${API_BASE_URL}/admin/season_tickets/fields`),
    getRows: () =>
      Api.axios('get', `${API_BASE_URL}/admin/season_tickets/rows`),
    getCurrentSeason: () =>
      Api.axios('get', `${API_BASE_URL}/admin/season_tickets/seasons/current`),
    getSeasons: () =>
      Api.axios('get', `${API_BASE_URL}/admin/season_tickets/seasons`),
    getSeasonTickets: (payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/season_tickets/paginated`,
        true,
        payload
      ),
    getSeasonTicket: (seasonTicketID) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/season_tickets/${seasonTicketID}`
      ),
    getTicketStates: () =>
      Api.axios('get', `${API_BASE_URL}/admin/season_tickets/ticket_states`),
    getSeasonPrices: () =>
      Api.axios('get', `${API_BASE_URL}/admin/season_tickets/season_prices`),
    assignSeasonTicket: (payload) =>
      Api.axios('post', `${API_BASE_URL}/admin/season_tickets`, true, payload),
    editSeasonTicket: (payload, id) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/season_tickets/${id}`,
        true,
        payload
      ),
    deleteSeasonTicket: (id) =>
      Api.axios('delete', `${API_BASE_URL}/admin/season_tickets/${id}`),
    assignSeasonTickets: (payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/season_tickets/multiple`,
        true,
        payload
      ),
    updateUsersSeasonTickets: (payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/season_tickets/updateUsersTickets`,
        true,
        payload
      ),
    deleteSeasonTickets: (payload) =>
      Api.axios(
        'delete',
        `${API_BASE_URL}/admin/season_tickets/multiple`,
        true,
        payload
      ),
    createSeasonPriceGroup: (payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/matches/season_prices`,
        true,
        payload
      ),
    printSeasonTicket: (seasonTicketId) =>
      Api.axios(
        'PUT',
        `${API_BASE_URL}/admin/season_tickets/${seasonTicketId}/print`
      ),
    populateSeasonTickets: (payload) =>
      Api.axios(
        'POST',
        `${API_BASE_URL}/admin/season_tickets/populate_tickets`,
        true,
        payload
      ),
  };
  static tickets = {
    getCurrentSeasonMatches: () =>
      Api.axios('get', `${API_BASE_URL}/admin/tickets/current_season_matches`),
    getFields: (matchID) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/tickets/fields?match_id=${matchID}`
      ),
    getRows: () => Api.axios('get', `${API_BASE_URL}/admin/tickets/rows`),
    getSeats: (matchID, fieldID, rowID, query) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/tickets/assign_tickets?match_id=${matchID}&field_id=${fieldID}&row_id=${rowID}&q=${query}`
      ),
    getTickets: (
      matchID,
      fieldID,
      rowID,
      ticketStateId,
      priceGroupId,
      shareable,
      vip,
      query,
      page,
      pageSize,
      sortType,
      sortOrder
    ) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/tickets/assign_tickets/paginated?
      match_id=${matchID}&field_id=${fieldID}${rowID ? `&row_id=${rowID}` : ''}&ticket_state_id=${ticketStateId}&price_group_id=${priceGroupId}&is_shareable=${shareable}&is_vip=${vip}&q=${query}&page=${page}&count=${pageSize}&type=${sortType}&order=${sortOrder}`
      ),
    getTicketStates: () =>
      Api.axios('get', `${API_BASE_URL}/admin/tickets/ticket_states`),
    getMatchTicket: (seatID) =>
      Api.axios('get', `${API_BASE_URL}/admin/tickets/${seatID}`),
    getPriceGroups: () =>
      Api.axios('get', `${API_BASE_URL}/admin/tickets/price_groups`),
    getSeasonTickets: (userID, seasonID) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/tickets/season_tickets?season_id=${seasonID}&user_id=${userID}`
      ),
    createReservations: (payload) =>
      Api.axios('post', `${API_BASE_URL}/admin/tickets/presale`, true, payload),
    assignTicket: (payload) =>
      Api.axios('post', `${API_BASE_URL}/admin/tickets`, true, payload),
    assignTickets: (payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/tickets/multiple`,
        true,
        payload
      ),
    editMatchTicket: (id, payload) =>
      Api.axios('put', `${API_BASE_URL}/admin/tickets/${id}`, true, payload),
    deleteTicket: (id) =>
      Api.axios('delete', `${API_BASE_URL}/admin/tickets/${id}`),
    deleteTickets: (payload) =>
      Api.axios(
        'delete',
        `${API_BASE_URL}/admin/tickets/multiple`,
        true,
        payload
      ),
    deleteMatchReservation: (id) =>
      Api.axios(
        'delete',
        `${API_BASE_URL}/admin/tickets/seat_match_reservation/${id}`
      ),
    updateUsersTickets: (payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/tickets/updateUsersTickets`,
        true,
        payload
      ),
    assignTicketsFromSeasonTickets: (payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/tickets/assign_tickets_from_season_tickets`,
        true,
        payload
      ),
    ticketCountByEvent: (club, currentPage, pageSize, searchQuery) =>
      Api.axios(
        'GET',
        `${getServiceUrl('ticket')}/ticket/ticket-count-by-event?currentPage=${currentPage}&pageSize=${pageSize}&search=${searchQuery}`,
        true,
        { header: { 'fep-context-name': club } }
      ),
    updateEventNameofTickets: (club, payload, currentPage, pageSize) =>
      Api.axios(
        'PUT',
        `${getServiceUrl('ticket')}/ticket/update-event-name?currentPage=${currentPage}&pageSize=${pageSize}`,
        true,
        { header: { 'fep-context-name': club }, ...payload }
      ),
    postTicksterEvents: () =>
      Api.axios('POST', `${getServiceUrl('ticket')}/admin/tickster/events`),
    getTicksterEvents: () =>
      Api.axios('GET', `${getServiceUrl('ticket')}/events`),
    updateTicksterEvents: (eventId) =>
      Api.axios(
        'PUT',
        `${getServiceUrl('ticket')}/admin/tickster/events/${eventId}`
      ),
  };

  static packages = {
    getDiscount: (id) =>
      Api.axios('get', `${API_BASE_URL}/admin/rule_packages/${id}`),
    getDiscounts: () => Api.axios('get', `${API_BASE_URL}/admin/rule_packages`),
    saveDiscount: (payload) =>
      Api.axios('post', `${API_BASE_URL}/admin/rule_packages`, true, payload),
    updateDiscount: (id, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/rule_packages/${id}`,
        true,
        payload
      ),
    deleteDiscount: (id) =>
      Api.axios('delete', `${API_BASE_URL}/admin/rule_packages/${id}`),
    getDiscountModifiers: () =>
      Api.axios('get', `${API_BASE_URL}/admin/rule_packages/get_modifiers`),
    getCurrentSeasonPrices: () =>
      Api.axios('get', `${API_BASE_URL}/admin/rule_packages/season_prices`),
    getBalanceName: () =>
      Api.axios('get', `${API_BASE_URL}/admin/fansaldo_name`),
    getBalancePackages: () =>
      Api.axios('get', `${API_BASE_URL}/admin/fansaldo`),
    getBalancePackage: (balancePackageID) =>
      Api.axios('get', `${API_BASE_URL}/admin/fansaldo/${balancePackageID}`),
    createBalancePackage: (payload) =>
      Api.axios('post', `${API_BASE_URL}/admin/fansaldo`, true, payload),
    editBalancePackage: (payload, balancePackageID) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/fansaldo/${balancePackageID}`,
        true,
        payload
      ),
    deleteBalancePackage: (balancePackageID) =>
      Api.axios('delete', `${API_BASE_URL}/admin/fansaldo/${balancePackageID}`),
    changeBalancePackageName: (payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/fansaldo/settings`,
        true,
        payload
      ),
    getPackages: () => Api.axios('get', `${API_BASE_URL}/admin/packages`),
    getPriceGroups: () =>
      Api.axios('get', `${API_BASE_URL}/admin/matches/price_groups`),
    getProductGroups: () =>
      Api.axios('get', `${API_BASE_URL}/admin/packages/product_groups`),
    getProductGroupsType: (type) =>
      Api.axios('get', `${API_BASE_URL}/admin/packages/product_groups/${type}`),
    savePackage: (payload) =>
      Api.axios('post', `${API_BASE_URL}/admin/packages`, true, payload),
    getPackage: (packageID) =>
      Api.axios('get', `${API_BASE_URL}/admin/packages/${packageID}`),
    updatePackage: (packageID, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/packages/${packageID}`,
        true,
        payload
      ),
    deletePackage: (packageID) =>
      Api.axios('delete', `${API_BASE_URL}/admin/packages/${packageID}`),
  };

  static stadium = {
    getStadiums: () => Api.axios('get', `${API_BASE_URL}/admin/stadium`),
    getStadiumByID: (stadiumID) =>
      Api.axios('get', `${API_BASE_URL}/admin/stadium/${stadiumID}`),
    createStadium: (payload) =>
      Api.axios('post', `${API_BASE_URL}/admin/stadium`, true, payload),
    updateStadium: (stadiumID, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/stadium/${stadiumID}`,
        true,
        payload
      ),
    deleteStadium: (stadiumID) =>
      Api.axios('delete', `${API_BASE_URL}/admin/stadium/${stadiumID}`),
    getFields: (stadiumID) =>
      Api.axios('get', `${API_BASE_URL}/admin/stadium/${stadiumID}/fields`),
    getFieldsOfOneStadium: () =>
      Api.axios('get', `${API_BASE_URL}/admin/stadium/fields`),
    getStadiumFieldByID: (fieldID) =>
      Api.axios('get', `${API_BASE_URL}/admin/stadium/fields/${fieldID}`),
    createField: (payload) =>
      Api.axios('post', `${API_BASE_URL}/admin/stadium/fields`, true, payload),
    getFieldTypes: () =>
      Api.axios('get', `${API_BASE_URL}/admin/stadium/field_types`),
    updateField: (fieldID, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/stadium/fields/${fieldID}`,
        true,
        payload
      ),
    deleteField: (fieldID) =>
      Api.axios('delete', `${API_BASE_URL}/admin/stadium/fields/${fieldID}`),
    getRows: (fieldID) =>
      Api.axios('get', `${API_BASE_URL}/admin/stadium/fields/${fieldID}/rows`),
    createRow: (payload) =>
      Api.axios('post', `${API_BASE_URL}/admin/stadium/rows`, true, payload),
    getRowByID: (rowID) =>
      Api.axios('get', `${API_BASE_URL}/admin/stadium/rows/${rowID}`),
    // updateRow: (rowID, payload) => Api.axios('put', `${API_BASE_URL}/admin/stadium/rows/${rowID}`, true, payload),
    updateRow: (rowID, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/stadium/rows/${rowID}`,
        true,
        payload
      ),
    deleteRow: (rowID) =>
      Api.axios('delete', `${API_BASE_URL}/admin/stadium/rows/${rowID}`),
    getSeatStates: () =>
      Api.axios('get', `${API_BASE_URL}/admin/stadium/seat_states`),
    createSeats: (rowID, payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/stadium/rows/${rowID}/seats`,
        true,
        payload
      ),
    updateSeats: (fieldID, rowId, seats) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/stadium/seats`,
        true,
        (fieldID, rowId, seats)
      ),
  };

  static employees = {
    addEmployee: (payload, companyID) =>
      Api.axios(
        'post',
        `${getServiceUrl('company')}/employee/admin/${companyID}`,
        true,
        payload
      ),
    getEmployees: (companyID) =>
      Api.axios(
        'GET',
        `${getServiceUrl('company')}/employee/admin/getForCompany/${companyID}`
      ),
    removeEmployee: (employeeID, payload) =>
      Api.axios(
        'DELETE',
        `${getServiceUrl('company')}/employee/admin/${employeeID}`,
        true,
        payload
      ),
    getEmployeeById: (employeeID) =>
      Api.axios(
        'get',
        `${getServiceUrl('company')}/employee/${employeeID}`
      ).then((res) => {
        return res.data;
      }),
    editEmployee: (employeeID, payload) =>
      Api.axios(
        'put',
        `${getServiceUrl('company')}/employee/admin/${employeeID}`,
        true,
        payload
      ),
    getUserCompaniesForTeam: (userId) =>
      Api.axios(
        'get',
        `${getServiceUrl('company')}/employee/admin/getUserCompaniesForTeam/${userId}`
      ),
  };
  static seasons = {
    getSeasons: () => Api.axios('get', `${API_BASE_URL}/admin/matches/seasons`),
    getSeasonByID: (seasonID) =>
      Api.axios('get', `${API_BASE_URL}/admin/matches/seasons/${seasonID}`),
    getSeasonPrices: (seasonID) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/matches/seasons/${seasonID}/prices`
      ),
    updateSeason: (seasonID, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/matches/seasons/${seasonID}`,
        true,
        payload
      ),
    updateSeasonPrices: (payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/matches/season_prices`,
        true,
        payload
      ),
    createSeason: (payload) =>
      Api.axios('post', `${API_BASE_URL}/admin/matches/seasons`, true, payload),
    getLastSeasonPrices: () =>
      Api.axios('get', `${API_BASE_URL}/admin/matches/last_season_prices`),
    upsertSeasonTicketReservations: (seasonID, payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/matches/seasons/${seasonID}/create_tickets`,
        true,
        payload
      ),
  };
  static cnc = {
    getKiosks: (order, reverse, searchText, page, pageSize) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/click_and_collect/kiosks?order=${order}&reverse=${reverse}&q=${searchText}&page=${page}&count=${pageSize}`
      ),
    getKiosksPlain: () =>
      Api.axios('get', `${API_BASE_URL}/admin/click_and_collect/kiosks/select`),
    deleteKiosks: (id) =>
      Api.axios(
        'delete',
        `${API_BASE_URL}/admin/click_and_collect/kiosks/${id}`
      ),
    updateKiosks: (id, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/click_and_collect/kiosks/${id}`,
        true,
        payload
      ),
    createKiosk: (payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/click_and_collect/kiosks`,
        true,
        payload
      ),
    getProductList: () =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/click_and_collect/products?plain=1`
      ),
    getKioskProducts: (id, payload) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/click_and_collect/kiosks/${id}`,
        true,
        payload
      ),
    updateProducts: (id, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/click_and_collect/kiosks/${id}/products`,
        true,
        payload
      ),
    getProductByID: (id) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/click_and_collect/products/${id}`
      ),
    updateProductByID: (id, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/click_and_collect/products/${id}`,
        true,
        payload
      ),
    getProducts: (page, pageSize, orderBy, reverse, query, filter) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/click_and_collect/products?count=${pageSize}&page=${page}&order=${orderBy}&reverse=${reverse}&q=${query}&filter=${filter}`
      ),
    deleteProduct: (productID) =>
      Api.axios(
        'delete',
        `${API_BASE_URL}/admin/click_and_collect/products/${productID}`
      ),
    getCategoryByID: (categoryID) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/click_and_collect/categories/${categoryID}`
      ),
    updateCategory: (id, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/click_and_collect/categories/${id}`,
        true,
        payload
      ),
    deleteCategory: (categoryID) =>
      Api.axios(
        'delete',
        `${API_BASE_URL}/admin/click_and_collect/categories/${categoryID}`
      ),
    getProductCategories: () =>
      Api.axios('get', `${API_BASE_URL}/admin/click_and_collect/categories`),
    createProduct: (payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/click_and_collect/products`,
        true,
        payload
      ),
    createCategory: (payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/click_and_collect/categories`,
        true,
        payload
      ),
    getAuctions: (page, pageSize, query, orderBy, reverse, kioskFilter = '') =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/click_and_collect/auctions?count=10&page=${page}&count=${pageSize}&order=${orderBy}&reverse=${reverse}&q=${query}&kiosk=${kioskFilter}`
      ),
    deleteAuction: (auctionID) =>
      Api.axios(
        'delete',
        `${API_BASE_URL}/admin/click_and_collect/auctions/${auctionID}`
      ),
    searchProducts: (query) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/click_and_collect/products?plain=1&q=${query}`
      ),
    getAllAuctions: () =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/click_and_collect/kiosks/select?auction=1`
      ),
    createAuction: (payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/click_and_collect/auctions`,
        true,
        payload
      ),
    editAuction: (id, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/click_and_collect/auctions/${id}`,
        true,
        payload
      ),
    getAuctionByID: (id) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/click_and_collect/auctions/${id}`
      ),
    updateKioskStatus: (id) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/click_and_collect/kiosks/${id}/status`
      ),
  };

  static auctions = {
    getAuctionHouses: ({ page, take }, { orderBy, direction }) =>
      Api.axios(
        'get',
        `${getServiceUrl('auctions')}/admin/auction-house?page=${page}&take=${take}&orderBy=${orderBy}&direction=${direction}`
      ).then(({ data }) => data),
    createAuctionHouse: (payload) =>
      Api.axios(
        'post',
        `${getServiceUrl('auctions')}/admin/auction-house`,
        true,
        payload
      ).then(({ data }) => data),
    getAuctionHouse: (auctionHouseId) =>
      Api.axios(
        'get',
        `${getServiceUrl('auctions')}/admin/auction-house/${auctionHouseId}`
      ).then(({ data }) => data),
    updateAuctionHouse: (auctionHouseId, payload) =>
      Api.axios(
        'put',
        `${getServiceUrl('auctions')}/admin/auction-house/${auctionHouseId}`,
        true,
        payload
      ),
    getAuctionItemsForHouse: (auctionHouseId) =>
      Api.axios(
        'get',
        `${getServiceUrl('auctions')}/admin/auction-item/forHouse/${auctionHouseId}`
      ).then(({ data }) => data),
    getAuctionHouseAccess: (auctionHouseId) =>
      Api.axios(
        'get',
        `${getServiceUrl('auctions')}/admin/access/${auctionHouseId}`
      ).then(({ data }) => data),
    updateAuctionHouseAccess: (auctionHouseId, payload) =>
      Api.axios(
        'put',
        `${getServiceUrl('auctions')}/admin/access/${auctionHouseId}`,
        true,
        payload
      ),
    getAuctionItem: (auctionItemId) =>
      Api.axios(
        'get',
        `${getServiceUrl('auctions')}/admin/auction-item/${auctionItemId}`
      ).then(({ data }) => data),
    updateAuctionItem: (auctionItemId, payload) =>
      Api.axios(
        'put',
        `${getServiceUrl('auctions')}/admin/auction-item/${auctionItemId}`,
        true,
        payload
      ),
    createAuctionItem: (auctionHouseId, payload) =>
      Api.axios(
        'post',
        `${getServiceUrl('auctions')}/admin/auction-item/${auctionHouseId}`,
        true,
        payload
      ),
    getAuctionsPressAmount: (payload) =>
      Api.axios(
        'get',
        `${getServiceUrl('stats-new')}/auctions/getAuctionsData`,
        true,
        payload
      ),
    deleteAuctionHouse: (auctionHouseId) =>
      Api.axios(
        'delete',
        `${getServiceUrl('auctions')}/admin/auction-house/${auctionHouseId}`
      ),
    deleteAuctionItem: (auctionItemId) =>
      Api.axios(
        'delete',
        `${getServiceUrl('auctions')}/admin/auction-item/${auctionItemId}`
      ),
    getAuctionItemBids: (auctionItemId, { page, take }) =>
      Api.axios(
        'get',
        `${getServiceUrl('auctions')}/admin/auction-bid/${auctionItemId}?page=${page}&take=${take}`
      ).then(({ data }) => data),
    deleteAuctionItemBid: (auctionBidId) =>
      Api.axios(
        'delete',
        `${getServiceUrl('auctions')}/admin/auction-bid/${auctionBidId}`
      ),
    contactAuction: async (type, newsId, payload) =>
      Api.axios(
        'POST',
        `${getServiceUrl('auctions')}/admin/communication/${newsId}?type=${type}`,
        true,
        payload
      ),
    updateScheduledMessage: async (type, newsId, messageId, payload) => {
      return Api.axios(
        'PUT',
        `${getServiceUrl('auctions')}/admin/communication/${newsId}/${messageId}?type=${type}`,
        true,
        payload
      );
    },
    deleteScheduledMessage: async (messageId) => {
      return Api.axios(
        'DELETE',
        `${getServiceUrl('auctions')}/admin/communication/${messageId}`,
        true
      );
    },
  };

  static kiosks = {
    getKioskByID: (id) =>
      Api.axios('get', `${API_BASE_URL}/admin/click_and_collect/kiosks/${id}`),
    getKiosks: () =>
      Api.axios('get', `${API_BASE_URL}/admin/kiosk_sales/kiosks`),
    updateKioskStatus: (id) =>
      Api.axios('put', `${API_BASE_URL}/admin/kiosk_sales/kiosks/${id}/status`),
    getKioskOrders: (id) =>
      Api.axios('get', `${API_BASE_URL}/admin/kiosk_sales/kiosks/${id}/orders`),
    putKioskOrderStatus: (id, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/kiosk_sales/orders/${id}/status`,
        true,
        payload
      ),
    getAuctionOrders: (id) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/kiosk_sales/kiosks/${id}/auctions`
      ),
    getAuctionBids: (id) =>
      Api.axios('get', `${API_BASE_URL}/admin/kiosk_sales/auctions/${id}/bids`),
    deleteAuctionBid: (auctionID, bidID) =>
      Api.axios(
        'delete',
        `${API_BASE_URL}/admin/kiosk_sales/auctions/${auctionID}/bids/${bidID}`
      ),
    updateAuctionOrder: (kioskID, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/kiosk_sales/kiosks/${kioskID}/auction_order`,
        true,
        payload
      ),
    getOrderHistory: (kioskID, page, pageSize, type, query) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/kiosk_sales/kiosks/${kioskID}/order_history?page=${page}&count=${pageSize}&${type}=${query}`
      ),
    refundOrder: (orderID) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/kiosk_sales/orders/${orderID}/refund`
      ),
    getKioskProducts: (kioskID) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/kiosk_sales/kiosks/${kioskID}/products`
      ),
    updateProductOrder: (kioskID, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/kiosk_sales/kiosks/${kioskID}/product_order`,
        true,
        payload
      ),
    updateProductState: (kioskID, productID) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/kiosk_sales/kiosks/${kioskID}/product/${productID}/status`
      ),
    sendOrderPush: (orderID) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/kiosk_sales/orders/${orderID}/push`
      ),
  };

  static userDevices = {
    sendMessage: (payload) =>
      Api.axios('post', `${getServiceUrl('user-term')}/message`, true, payload),
    getDeviceLanguage: (userId) =>
      Api.axios(
        'get',
        `${getServiceUrl('user-term')}/user-topics/getDeviceLanguage/${userId}`,
        true
      ),
    getScreens: () =>
      Api.axios('get', `${API_BASE_URL}/admin/user_devices/get_screens`, true),
    getApiAccesses: () =>
      Api.axios(
        'GET',
        `${getServiceUrl('communication')}/admin/api/access`
      ).then(({ data }) => data),
    deleteApiAccess: (accessId) =>
      Api.axios(
        'DELETE',
        `${getServiceUrl('communication')}/admin/api/access/${accessId}`
      ),
    createApiAccess: (payload) =>
      Api.axios(
        'post',
        `${getServiceUrl('communication')}/admin/api/access`,
        true,
        payload
      ),
    getApiAccessById: (accessId) =>
      Api.axios(
        'GET',
        `${getServiceUrl('communication')}/admin/api/access/${accessId}`
      ),
    getAllNotificationLabels: () =>
      Api.axios(
        'GET',
        `${getServiceUrl('user-term')}/admin/notification-label`
      ).then(({ data }) => data),
    getNotificationLabel: (labelId) =>
      Api.axios(
        'GET',
        `${getServiceUrl('user-term')}/admin/notification-label/${labelId}`
      ).then(({ data }) => data),
    createNotificationLabel: (payload) =>
      Api.axios(
        'POST',
        `${getServiceUrl('user-term')}/admin/notification-label`,
        true,
        payload
      ),
    updateNotificationLabel: (labelId, payload) =>
      Api.axios(
        'PUT',
        `${getServiceUrl('user-term')}/admin/notification-label/${labelId}`,
        true,
        payload
      ),
    deleteNotificationLabel: (labelId) =>
      Api.axios(
        'DELETE',
        `${getServiceUrl('user-term')}/admin/notification-label/${labelId}`
      ),
  };
  static terms = {
    getUserTermApprovals: () =>
      Api.axios(
        'get',
        `${getServiceUrl('user-term')}/user-term/admin/approvals`
      ),
    getTermTypes: async () => {
      const graphQLClient = await this.getGraphQLClient('user-term');
      const query = gql`
        query {
          termTypes
        }
      `;
      const { termTypes } = await graphQLClient.request(query);
      return termTypes.map((item) => ({ type: item }));
    },
    getAllTerms: async () => {
      const graphQLClient = await this.getGraphQLClient('user-term');
      const query = gql`
        query {
          terms {
            type
            title
            intro
            description
          }
        }
      `;
      const { terms } = await graphQLClient.request(query);
      return terms;
    },
    getBlockedTermTypes: async (config) => {
      const graphQLClient = await this.getGraphQLClient('user-term');
      const query = gql`
        query {
          terms {
            type
          }
        }
      `;
      const { terms } = await graphQLClient.request(query, {
        teamId: `${config?.ntb_team_id}`,
      });
      return terms.map(({ type }) => type.toUpperCase());
    },
    getTerm: async (termType, config) => {
      const allTerms = await Api.terms.getAllTerms(config);
      const foundTerm = allTerms.find((item) => item.type === termType);
      if (!foundTerm) {
        throw new Error('Term not found');
      }
      return foundTerm;
    },

    createUpdateTerm: async ({ description, intro, title, type }) => {
      const graphQLClient = await this.getGraphQLClient('user-term');
      const mutation = gql`
        mutation DefineTerm(
          $description: String!
          $intro: String!
          $title: String!
          $type: String!
        ) {
          defineTerm(
            description: $description
            intro: $intro
            title: $title
            type: $type
          ) {
            type
          }
        }
      `;
      await graphQLClient.request(mutation, {
        description,
        intro,
        title,
        type,
      });
    },
  };
  static settings = {
    getTeams: () => Api.axios('get', `${API_BASE_URL}/admin/settings/teams`),
    getTeam: (id) =>
      Api.axios('get', `${API_BASE_URL}/admin/settings/teams/${id}`),
    getPriceGroups: () =>
      Api.axios('get', `${API_BASE_URL}/admin/settings/price_groups`),
    getPriceGroup: (id) =>
      Api.axios('get', `${API_BASE_URL}/admin/settings/price_groups/${id}`),
    getMatchTypes: () =>
      Api.axios('get', `${API_BASE_URL}/admin/settings/match_types`),
    getMatchType: (id) =>
      Api.axios('get', `${API_BASE_URL}/admin/settings/match_types/${id}`),
    getFieldTypes: () =>
      Api.axios('get', `${API_BASE_URL}/admin/settings/field_types`),
    getFieldType: (id) =>
      Api.axios('get', `${API_BASE_URL}/admin/settings/field_types/${id}`),
    deleteTeam: (id) =>
      Api.axios('delete', `${API_BASE_URL}/admin/settings/teams/${id}`),
    getFields: () =>
      Api.axios('get', `${API_BASE_URL}/admin/settings/price_groups/fields`),
    deletePriceGroup: (id) =>
      Api.axios('delete', `${API_BASE_URL}/admin/settings/price_groups/${id}`),
    deleteMatchType: (id) =>
      Api.axios('delete', `${API_BASE_URL}/admin/settings/match_types/${id}`),
    deleteFieldType: (id) =>
      Api.axios('delete', `${API_BASE_URL}/admin/settings/field_types/${id}`),
    createTeam: (payload) =>
      Api.axios('post', `${API_BASE_URL}/admin/settings/teams`, true, payload),
    updateTeam: (payload, id) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/settings/teams/${id}`,
        true,
        payload
      ),
    createMatchType: (payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/settings/match_types`,
        true,
        payload
      ),
    updateMatchType: (payload, id) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/settings/match_types/${id}`,
        true,
        payload
      ),
    createPriceGroup: (payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/settings/price_groups`,
        true,
        payload
      ),
    updatePriceGroup: (payload, id) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/settings/price_groups/${id}`,
        true,
        payload
      ),
    createFieldType: (payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/settings/field_types`,
        true,
        payload
      ),
    updateFieldType: (payload, id) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/settings/field_types/${id}`,
        true,
        payload
      ),
  };
  static import = {
    importSeasonTickets: (payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/import_season_tickets`,
        true,
        payload
      ),
    importStadiumSetup: (payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/import_stadium_setup`,
        true,
        payload
      ),
  };
  static dashboard = {
    getAllWidgets: () =>
      Api.axios('GET', `${getServiceUrl('club')}/widget/all`, true),
    getActiveWidgets: (id) =>
      Api.axios('GET', `${getServiceUrl('club')}/widget/active-widgets`, true, {
        header: { 'fep-context': id },
      }),
    upsertActiveWidgets: (id, payload) =>
      Api.axios(
        'POST',
        `${getServiceUrl('club')}/widget/active-widgets`,
        true,
        { header: { 'fep-context': id }, ...payload }
      ),
    getMatchAttendance: () =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/reports/dashboard/match_attendance`
      ),
    getSoldTickets: () =>
      Api.axios('get', `${API_BASE_URL}/admin/reports/dashboard/sold_tickets`),
    getFutureMatches: () =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/reports/dashboard/future_matches`
      ),
    getSeasonTicketSales: () =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/reports/dashboard/season_tickets_sales`
      ),
    getPriceGroupSales: (season) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/reports/dashboard/price_group_sales/${season}`
      ),
    getStadiumStats: () =>
      Api.axios('get', `${API_BASE_URL}/admin/reports/dashboard/stadium_stats`),
    getMatchStats: (season) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/reports/dashboard/match_stats/${season}`
      ),
  };

  static box = {
    getMatches: () => Api.axios('get', `${API_BASE_URL}/admin/sales/matches`),
    getPriceGroups: (matchID) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/sales/matches/${matchID}/price_groups?box_office=true`
      ),
    getFieldTypes: (matchID) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/sales/matches/${matchID}/field_types`
      ),
    getFields: (id) =>
      Api.axios('get', `${API_BASE_URL}/admin/sales/fields?match_id=${id}`),
    getFieldStatus: (matchID) =>
      Api.axios('get', `${API_BASE_URL}/admin/sales/matches/${matchID}/status`),
    getRows: (matchID, fieldID) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/sales/matches/${matchID}/rows?field_id=${fieldID}`
      ),
    getSeats: (matchID, rowID) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/sales/matches/${matchID}/seats?row_id=${rowID}`
      ),
    getUnmarkedSeats: (matchID, payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/matches/${matchID}/give_unmarked_seat_reservations`,
        true,
        payload
      ),
    buyTickets: (payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/sales/buy_tickets`,
        true,
        payload
      ),
    buyUnmarkedTickets: (payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/sales/buy_unmarked_tickets`,
        true,
        payload
      ),
    getPrintHistory: (matchID, transaction = '') =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/sales/history/${matchID}?transaction=${transaction}`
      ),
  };

  static reports = {
    getSoldMatchTickets: (seasonID) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/reports/sold_match_tickets?season_id=${seasonID}`
      ),
    getSoldTickets: () =>
      Api.axios('get', `${API_BASE_URL}/admin/reports/sold_tickets`),
    exportSoldMatchTickets: (seasonID) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/reports/sold_match_tickets/export?season_id=${seasonID}`
      ),
    getSoldTicketsReport: () =>
      Api.axios('get', `${API_BASE_URL}/admin/reports/season_tickets_report`),
    getMatchAttendanceReport: () =>
      Api.axios('get', `${API_BASE_URL}/admin/reports/match_attendance`),
    getMatches: () => Api.axios('get', `${API_BASE_URL}/admin/reports/matches`),
    getPriceGroups: () =>
      Api.axios('get', `${API_BASE_URL}/admin/reports/price_groups`),
    getDiscountReport: (id) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/reports/getTicketDiscounts/${id}`
      ),
  };

  static invoices = {
    getBillable: (page, pageSize, query, seasonID, matchID = '') =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/payments/billable_query?count=${pageSize}&page=${page}&order=processed&reverse=1&season_id=${seasonID}&q=${query}${matchID && `&match_id=${matchID}`}`
      ),
    exportBillable: (query = '', seasonID = '', matchID = '') =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/payments/billable_query_csv?query=${query}&season_id=${seasonID}&match_id=${matchID}`
      ),
    setHandled: (billableID, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/payments/${billableID}/process`,
        true,
        payload
      ),
    getMatchesByDates: (from, to) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/reports/getBillingMatches?from=${from}&to=${to}`
      ),
    getBillableByDates: (from, to) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/reports/billing?from=${from}&to=${to}`
      ),
    getSeasonBillableByDates: (from, to) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/reports/season_billing?from=${from}&to=${to}`
      ),
    getKioskBillableByDates: (from, to) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/reports/kiosk_billing?from=${from}&to=${to}`
      ),
  };

  static controlImages = {
    getControlImages: () =>
      Api.axios('get', `${API_BASE_URL}/admin/settings/control_images`),
    getControlImage: (controlImageID) =>
      Api.axios(
        'get',
        `${API_BASE_URL}/admin/settings/control_images/${controlImageID}`
      ),
    createControlImage: (payload) =>
      Api.axios(
        'post',
        `${API_BASE_URL}/admin/settings/control_images`,
        true,
        payload
      ),
    updateControlImage: (controlImageID, payload) =>
      Api.axios(
        'put',
        `${API_BASE_URL}/admin/settings/control_images/${controlImageID}`,
        true,
        payload
      ),
    deleteControlImage: (controlImageID) =>
      Api.axios(
        'delete',
        `${API_BASE_URL}/admin/settings/control_images/${controlImageID}`
      ),
  };

  static discountCode = {
    ...Api.crud('newDiscount'),
  };

  static externalStores = {
    ...Api.crud('external_store'),
  };

  static clubadmin = {
    syncMatch: {
      fetch: () =>
        Api.axios(
          'GET',
          `${getServiceUrl('matchconnector')}/match-connector`,
          true
        ),
    },
    profile: {
      fetchProfile: (id) =>
        Api.axios('GET', `${getServiceUrl('club')}/club/profile/${id}`, true),
      post: (payload) =>
        Api.axios(
          'POST',
          `${getServiceUrl('club')}/club/profile`,
          true,
          payload
        ),
      updateTeam: (team) =>
        Api.axios(
          'POST',
          `${getServiceUrl('club')}/team/${team.id}`,
          true,
          team
        ),
      updateTeamOrders: (teams) =>
        Api.axios(
          'PUT',
          `${getServiceUrl('club')}/team/updateTeamOrder`,
          true,
          teams
        ),
    },
    seasons: {
      all: () => Api.axios('GET', `${getServiceUrl('matchInfo')}/season`, true),
      create: (payload) =>
        Api.axios(
          'POST',
          `${getServiceUrl('matchInfo')}/season`,
          true,
          payload
        ),
      update: (id, payload) =>
        Api.axios(
          'PUT',
          `${getServiceUrl('matchInfo')}/season/${id}`,
          true,
          payload
        ),
      fetch: (id) =>
        Api.axios('GET', `${getServiceUrl('matchInfo')}/season/${id}`, true),
    },
    teams: {
      all: (page) =>
        Api.axios('Get', `${getServiceUrl('matchInfo')}/team/paginated`, true, {
          page: page.currentPage,
          count: page.pageSize,
        }),
      fetchTeams: () =>
        Api.axios('GET', `${getServiceUrl('matchInfo')}/team/all`, true),
      fetch: (id) =>
        Api.axios('Get', `${getServiceUrl('matchInfo')}/team/${id}`, true),
      update: (id, payload) =>
        Api.axios(
          'PUT',
          `${getServiceUrl('matchinfo')}/team/${id}`,
          true,
          payload
        ),
      create: (payload) =>
        Api.axios('POST', `${getServiceUrl('matchinfo')}/team`, true, payload),
      getAssosciatedTeams: (matchId) =>
        Api.axios(
          'GET',
          `${getServiceUrl('matchInfo')}/league/teamsByMatch/${matchId}`,
          true
        ),
      getPlayers: (id) =>
        Api.axios('Get', `${getServiceUrl('matchinfo')}/player/${id}`, true),
    },
    leagues: {
      syncDailies: () =>
        Api.axios(
          'GET',
          `${getServiceUrl('matchinfo')}/league/syncDailies`,
          true
        ),
      all: () => Api.axios('GET', `${getServiceUrl('matchInfo')}/league`, true),
      fetch: (id) => Api.axios('GET', `${getServiceUrl('matchInfo')}/league/${id}`, true),
      update: (pauload) => Api.axios('PUT', `${getServiceUrl('matchInfo')}/league`, true, pauload),
      create: (payload) => Api.axios('POST', `${getServiceUrl('matchInfo')}/league`, true, payload),
      syncLeagueMatches: (leagueId) => Api.axios('POST', `${getServiceUrl('matchinfo')}/league/syncLeagueMatches/${leagueId}`, true),
      initializeMatches: (leagueId) => Api.axios('GET', `${getServiceUrl('matchinfo')}/league/syncPlayers/${leagueId}`, true),
      getLeaguesForTeam: (teamId) => Api.axios('GET', `${getServiceUrl('matchinfo')}/league/leaguesForTeam/${teamId}`),
      setLeaguesForTeam: (teamId, payload) => Api.axios('POST', `${getServiceUrl('matchinfo')}/league/leaguesForTeam/${teamId}`, true, payload),
      createMatchesForLeague: (leagueId) => Api.axios('POST', `${getServiceUrl('matchinfo')}/league/createMatchesForLeague/${leagueId}`, true),
      updateRoster: (teamId) => Api.axios('GET', `${getServiceUrl('matchinfo')}/league/updateRoster/${teamId}`, true),
    },
    match: {
      todaysMatches: () =>
        Api.axios(
          'GET',
          `${getServiceUrl('matchinfo')}/league/todaysMatches`,
          true
        ),
      fetch: (id) =>
        Api.axios('GET', `${getServiceUrl('matchInfo')}/match/${id}`, true),
      getClubMatchesForSeason: (seasonId, teamIds) =>
        Api.axios(
          'Get',
          `${getServiceUrl('matchInfo')}/match/seasonMatchesForTeams`,
          true,
          { seasonId, teamIds }
        ),
      getLeaguesForTeams: (seasonId, teamIds) =>
        Api.axios(
          'GET',
          `${getServiceUrl('matchInfo')}/match/seasonLeaguesForTeams`,
          true,
          { teamIds, seasonId }
        ),
      getTeamMatchesForLeague: (leagueId, teamIds) =>
        Api.axios(
          'GET',
          `${getServiceUrl('matchInfo')}/match/getTeamMatchesForLeague`,
          true,
          { leagueId, teamIds }
        ),
      getLeagueWithMatches: (leagueId) =>
        Api.axios(
          'GET',
          `${getServiceUrl('matchInfo')}/league/${leagueId}`,
          true
        ),
      getFanRating: (matchID, teamID, page, count) =>
        Api.axios(
          'GET',
          `${getServiceUrl('matchinfo')}/player/fanRating/admin/${matchID}/${teamID}/objects?page=${page}&count=${count}`,
          true
        ),
      getTopFanRating: (matchID, teamID) =>
        Api.axios(
          'GET',
          `${getServiceUrl('matchinfo')}/player/fanRating/${matchID}/${teamID}`,
          true
        ),
      getUserRating: (matchID, teamID) =>
        Api.axios(
          'GET',
          `${getServiceUrl('matchinfo')}/player/fanrating/admin/${matchID}/${teamID}/userVotes`,
          true
        ),
      getAllRating: (matchID, teamID, page, count) =>
        Api.axios(
          'GET',
          `${getServiceUrl('matchinfo')}/player/fanRating/admin/${matchID}/${teamID}/objects?page=${page}&count=${count}&noPaginate=true`,
          true
        ),
    },
    spotlight: {
      refreshCache: () =>
        Api.axios(
          'POST',
          `${getServiceUrl('spotlight')}/admin/db-management`,
          true
        ),
    },
    auctions: {
      refreshCache: () =>
        Api.axios(
          'POST',
          `${getServiceUrl('auctions')}/admin/db-management`,
          true
        ),
    },
    all: () => Api.axios('get', `${getServiceUrl('club')}/club`, true),
    fetchTeams: () => Api.axios('get', `${getServiceUrl('club')}/team`, true),
    createTeam: (team) =>
      Api.axios('POST', `${getServiceUrl('club')}/team`, true, team),
    create: (club) =>
      Api.axios('POST', `${getServiceUrl('club')}/club`, true, club),
    get: (clubId) =>
      Api.axios('GET', `${getServiceUrl('club')}/club/${clubId}`, true),
    getClubConfig: (clubName) =>
      Api.axios('GET', `${getServiceUrl('club')}/club/teamConfig`, true, {
        header: { 'fep-context-name': clubName },
      }),
    update: (payload, clubId) =>
      Api.axios(
        'PUT',
        `${getServiceUrl('club')}/club/${payload.id}`,
        true,
        {
          ...payload,
          ...(clubId ? { header: { 'fep-context': clubId } } : {}),
        },
        false,
        !clubId
      ),
  };

  static events = {
    sendMessage: (eventID, payload) =>
      Api.axios(
        'POST',
        `${getServiceUrl('events')}/admin/contact/sendMessage/${eventID}`,
        true,
        payload
      ),
    scheduleMessage: (eventID, payload) =>
      Api.axios(
        'POST',
        `${getServiceUrl('events')}/admin/contact/scheduleMessage/${eventID}`,
        true,
        payload
      ),
    updateMessage: (eventID, payload, messageID) =>
      Api.axios(
        'PUT',
        `${getServiceUrl('events')}/admin/contact/scheduleMessage/${eventID}/${messageID}`,
        true,
        payload
      ),
    deleteMessage: (eventID, messageID) =>
      Api.axios(
        'DELETE',
        `${getServiceUrl('events')}/admin/contact/scheduleMessage/${eventID}/${messageID}`,
        true
      ),
    getEventCategories: () =>
      Api.axios('GET', `${getServiceUrl('events')}/admin/categories`, true),
    removeEventTicket: (eventID, ticketID) =>
      Api.axios(
        'DELETE',
        `${getServiceUrl('events')}/admin/events/${eventID}/${ticketID}`
      ),
    getParticipants: (eventId) =>
      Api.axios(
        'GET',
        `${getServiceUrl('events')}/participant/forEvent/${eventId}`,
        true
      ),
    getDeletedParticipants: (eventId) =>
      Api.axios(
        'GET',
        `${getServiceUrl('events')}/admin/participant/deleted/${eventId}`,
        true
      ),
    updateParticipant: (payload, participantId) =>
      Api.axios(
        'PUT',
        `${getServiceUrl('events')}/admin/participant/${participantId}`,
        true,
        payload
      ),
    markAttendance: (attendedAmount, attended, participantId) =>
      Api.axios(
        'POST',
        `${getServiceUrl('events')}/admin/participant/markAttendance/${participantId}`,
        true,
        { attendedAmount, attended }
      ),
    addParticipants: (payload, eventID) =>
      Api.axios(
        'POST',
        `${getServiceUrl('events')}/admin/participant/${eventID}`,
        true,
        payload
      ),
    editParticipants: (payload, participantID) =>
      Api.axios(
        'PUT',
        `${getServiceUrl('events')}/admin/participant/${participantID}`,
        true,
        payload
      ),
    removeParticipants: (payload, participantID) =>
      Api.axios(
        'DELETE',
        `${getServiceUrl('events')}/admin/participant/${participantID}`,
        true,
        payload
      ),
    exportParticipantsCSV: (eventID, userLanguage) =>
      Api.axios(
        'GET',
        `${getServiceUrl('events')}/admin/participant/csv/${eventID}?lang=${userLanguage}`,
        true
      ),
    all: () => Api.axios('get', `${getServiceUrl('events')}/events/`, true),
    show: (id) => Api.axios('get', `${getServiceUrl('events')}/events/${id}`),
    delete: (id) =>
      Api.axios(
        'delete',
        `${getServiceUrl('events')}/admin/events/${id}`,
        true
      ),
    create: (payload) =>
      Api.axios(
        'post',
        `${getServiceUrl('events')}/admin/events`,
        true,
        payload
      ),
    update: (payload, id) =>
      Api.axios(
        'put',
        `${getServiceUrl('events')}/admin/events/${id}`,
        true,
        payload
      ),
    fetchTiers: async (config) => {
      const results = await Api.tiers.allTiers(config);
      const tiers = results.data.map((tier) => ({
        label: tier.name,
        value: tier.id,
      }));
      return tiers;
    },
    upcomingEvents: (limit = 5) =>
      Api.axios(
        'GET',
        `${getServiceUrl('events')}/events/upcoming/${limit}`,
        true
      ),
    addAdditionalNotes: (payload, participantId) =>
      Api.axios(
        'post',
        `${getServiceUrl('events')}/admin/participant/notes/${participantId}`,
        true,
        payload
      ),
    getParticipantStats: async (
      { page, take },
      { orderBy, direction, startDate, endDate, eventCategory },
      search
    ) => {
      const baseUrl = `${getServiceUrl('events')}/admin/participant/participantStats`;
      const params = new URLSearchParams({
        page,
        take,
        orderBy,
        direction,
      });

      if (search) {
        params.append('companyName', search);
      }
      if (startDate) {
        params.append('dateGreaterThan', startDate);
      }
      if (endDate) {
        params.append('dateLowerThan', endDate);
      }
      if (eventCategory) {
        params.append('eventCategory', eventCategory);
      }

      const { data } = await Api.axios(
        'get',
        `${baseUrl}?${params.toString()}`
      );
      return data;
    },
    deleteWaitingParticipant: (waitingParticipantId) =>
      Api.axios(
        'DELETE',
        `${getServiceUrl('events')}/admin/waitlist/${waitingParticipantId}`
      ),
    getWaitingParticipants: (eventId) =>
      Api.axios(
        'get',
        `${getServiceUrl('events')}/admin/waitlist/${eventId}`
      ).then(({ data }) => data),
    promoteEventParticipant: (participantId) =>
      Api.axios(
        'post',
        `${getServiceUrl('events')}/admin/waitlist/${participantId}`
      ),
    addWaitingParticipantNotes: (payload, participantId) =>
      Api.axios(
        'put',
        `${getServiceUrl('events')}/admin/waitlist/notes/${participantId}`,
        true,
        payload
      ),
  };
  static eventCategories = {
    all: () => Api.axios('get', `${getServiceUrl('events')}/admin/categories`),
    update: (id, payload) =>
      Api.axios(
        'put',
        `${getServiceUrl('events')}/admin/categories/${id}`,
        true,
        payload
      ),
    clone: (id, payload) =>
      Api.axios(
        'post',
        `${getServiceUrl('events')}/admin/categories/clone/${id}`,
        true,
        { header: { 'fep-context': '127' }, ...payload }
      ),
    create: (payload) =>
      Api.axios(
        'post',
        `${getServiceUrl('events')}/admin/categories`,
        true,
        payload
      ),
    delete: (id) =>
      Api.axios(
        'DELETE',
        `${getServiceUrl('events')}/admin/categories/${id}`,
        true
      ),
    getCompanyConfiguration: (id) =>
      Api.axios(
        'get',
        `${getServiceUrl('events')}/admin/categories/getForCompany/${id}`,
        true
      ),
    updateCompanyConfiguration: (id, payload) =>
      Api.axios(
        'put',
        `${getServiceUrl('events')}/admin/categories/updateConfiguration/${id}`,
        true,
        payload
      ),
    bulkUpdateCompanyConfiguartions: async (payload) =>
      Api.axios(
        'put',
        `${getServiceUrl('events')}/admin/categories/updateConfigurations`,
        true,
        payload
      ),
  };

  static spotlight = {
    getGroups: ({ page, take }, { orderBy, direction }, searchText) =>
      Api.axios(
        'get',
        `${getServiceUrl('spotlight')}/admin/group?page=${page}&take=${take}&orderBy=${orderBy}&direction=${direction}${searchText ? `&search=${searchText}` : ''}`
      ).then(({ data }) => data),
    deleteGroup: (id) =>
      Api.axios(
        'DELETE',
        `${getServiceUrl('spotlight')}/admin/group/${id}`,
        true
      ),
    updateGroup: (id, payload) =>
      Api.axios(
        'PUT',
        `${getServiceUrl('spotlight')}/admin/group/${id}`,
        true,
        payload
      ),
    createGroup: (payload) =>
      Api.axios(
        'POST',
        `${getServiceUrl('spotlight')}/admin/group`,
        true,
        payload
      ),
    getGroupById: (id) =>
      Api.axios(
        'GET',
        `${getServiceUrl('spotlight')}/admin/group/${id}`,
        true
      ).then(({ data }) => data),
    getTileById: (id) =>
      Api.axios(
        'GET',
        `${getServiceUrl('spotlight')}/admin/tile/${id}`,
        true
      ).then(({ data }) => data),
    createTile: (payload) =>
      Api.axios(
        'POST',
        `${getServiceUrl('spotlight')}/admin/tile`,
        true,
        payload
      ).then(({ data }) => data),
    updateTile: (id, payload) =>
      Api.axios(
        'PUT',
        `${getServiceUrl('spotlight')}/admin/tile/${id}`,
        true,
        payload
      ),
    deleteTile: (id) =>
      Api.axios('DELETE', `${getServiceUrl('spotlight')}/admin/tile/${id}`),
    getTileAccess: (id) =>
      Api.axios(
        'GET',
        `${getServiceUrl('spotlight')}/admin/access/${id}`,
        true
      ).then(({ data }) => data),
    updateTileAccess: (id, payload) =>
      Api.axios(
        'PUT',
        `${getServiceUrl('spotlight')}/admin/access/${id}`,
        true,
        payload
      ),
    getTilesForGroup: (id) =>
      Api.axios(
        'GET',
        `${getServiceUrl('spotlight')}/admin/group/${id}`,
        true
      ).then(({ data }) => data.tiles),
    updateOrder: (groupId, payload) =>
      Api.axios(
        'PUT',
        `${getServiceUrl('spotlight')}/admin/tile/order/${groupId}`,
        true,
        payload
      ),
    getSegments: () =>
      Api.axios(
        'GET',
        `${getServiceUrl('spotlight')}/admin/segment`,
        true
      ).then(({ data }) => data),
    deleteSegment: (id) =>
      Api.axios('DELETE', `${getServiceUrl('spotlight')}/admin/segment/${id}`),
    updateSegment: (id, payload) =>
      Api.axios(
        'PUT',
        `${getServiceUrl('spotlight')}/admin/segment/${id}`,
        true,
        payload
      ),
    getSegmentById: (id) =>
      Api.axios(
        'GET',
        `${getServiceUrl('spotlight')}/admin/segment/${id}`,
        true
      ).then(({ data }) => data),
    createSegment: (payload) =>
      Api.axios(
        'POST',
        `${getServiceUrl('spotlight')}/admin/segment`,
        true,
        payload
      ),
    getUsersForSegment: (id) =>
      Api.axios(
        'GET',
        `${getServiceUrl('spotlight')}/admin/segment/${id}`,
        true
      ).then(({ data }) => data.users),
    deleteUserFromSegment: (segmentId, userId) =>
      Api.axios(
        'DELETE',
        `${getServiceUrl('spotlight')}/admin/segment/${segmentId}/${userId}`
      ),
    importSegmentUsers: (segmentId, users) => {
      const formValues = new FormData();
      formValues.append('users', users);
      return Api.axios(
        'post',
        `${getServiceUrl('spotlight')}/admin/segment/import/${segmentId}`,
        true,
        formValues,
        true
      ).then(({ data }) => data);
    },
    getSpotlightPressAmount: (payload) =>
      Api.axios(
        'get',
        `${getServiceUrl('stats-new')}/spotlight/getNewSpotlightData`,
        true,
        payload
      ),
  };

  static teams = {
    all: () => Api.axios('GET', `${getServiceUrl('club')}/team/`, true),
    allVendors: () => Api.axios('GET', `${getServiceUrl('club')}/vendor`, true),
    clubTeams: () =>
      Api.axios('GET', `${getServiceUrl('club')}/team/clubTeams`, true),
    createVendor: (payload) =>
      Api.axios('POST', `${getServiceUrl('club')}/vendor`, true, payload),
    getVendor: (id) =>
      Api.axios('GET', `${getServiceUrl('club')}/vendor/${id}`, true),
    transferTeams: () =>
      Api.axios('GET', `${getServiceUrl('matchinfo')}/team/all`),
  };

  static lottery = {
    getRefundInformation: (id, userId) =>
      Api.axios(
        'GET',
        `${getServiceUrl('lottery')}/admin/getRefundInformation/${id}/${userId}`,
        true
      ),
    update: (payload, id) =>
      Api.axios('PUT', `${getServiceUrl('lottery')}/admin/${id}`, true, {
        ...payload,
      }),
    create: (payload) =>
      Api.axios('POST', `${getServiceUrl('lottery')}/admin`, true, {
        ...payload,
      }),
    delete: (id, force = false, refund = false) =>
      Api.axios(
        'DELETE',
        `${getServiceUrl('lottery')}/admin/${id}?force=${force}&refund=${refund}`
      ),
    show: (id) => Api.axios('GET', `${getServiceUrl('lottery')}/admin/${id}`),
    getSponsors: () =>
      Api.axios('GET', `${API_BASE_URL}/admin/lottery/contributors`),
    createPrizes: (id, payload) =>
      Api.axios(
        'POST',
        `${getServiceUrl('lottery')}/admin/${id}/createPrizes`,
        true,
        { ...payload }
      ),
    tickets: (lotteryID, page, pageSize) =>
      Api.axios(
        'GET',
        `${getServiceUrl('lottery')}/admin/${lotteryID}/tickets?page=${page}&count=${pageSize}`
      ),
    draw: (lotteryID, payload) =>
      Api.axios(
        'POST',
        `${getServiceUrl('lottery')}/admin/${lotteryID}/draw`,
        true,
        payload
      ),
    push: (lotteryID) =>
      Api.axios('GET', `${API_BASE_URL}/admin/lottery/${lotteryID}/push`, true),
    updatePrize: (lotteryID, prizeID, payload) =>
      Api.axios(
        'PUT',
        `${API_BASE_URL}/admin/lottery/${lotteryID}/prizes/${prizeID}`,
        true,
        { ...payload }
      ),
    deletePrize: (lotteryID, prizeID) =>
      Api.axios(
        'DELETE',
        `${API_BASE_URL}/admin/lottery/${lotteryID}/prizes/${prizeID}`
      ),
    collect: (id) =>
      Api.axios('POST', `${getServiceUrl('lottery')}/admin/collect/${id}`),
    getChartData: (id) =>
      Api.axios('GET', `${getServiceUrl('lottery')}/admin/${id}/chartData`),
    getTotalSales: (year) =>
      Api.axios(
        'GET',
        `${getServiceUrl('lottery')}/admin/getTotalSales/${year}`
      ),
    all: (page, count) =>
      Api.axios(
        'GET',
        `${getServiceUrl('lottery')}/admin?page=${page}&count=${count}`
      ),
    setPrizeOrder: (lotteryID, payload) =>
      Api.axios(
        'POST',
        `${getServiceUrl('lottery')}/admin/${lotteryID}/setPrizeOrder`,
        true,
        { ...payload }
      ),
  };

  static app_layout = {
    app: {
      create: (clubId, payload) =>
        Api.axios(
          'PUT',
          `${getServiceUrl('app-layout')}/app-layout/${clubId}`,
          true,
          payload
        ),
      all: (clubId) =>
        Api.axios('GET', `${getServiceUrl('app-layout')}/app-layout/${clubId}`),
      getComponents: () =>
        Api.axios('GET', `${getServiceUrl('app-layout')}/app-layout`),
    },
    website: {
      create: (clubId, payload) =>
        Api.axios(
          'PUT',
          `${getServiceUrl('app-layout')}/admin/web-layout/${clubId}`,
          true,
          payload
        ),
      all: (clubId) =>
        Api.axios('GET', `${getServiceUrl('app-layout')}/web-layout/${clubId}`),
      getComponents: () =>
        Api.axios('GET', `${getServiceUrl('app-layout')}/admin/web-layout`),
    },
  };

  static push = {
    // TODO: need to change that
    getSingleNotificationStats: (id) =>
      Api.axios(
        'GET',
        `${getServiceUrl('communication')}/push-stats/${id}`,
        true
      ),
  };
  static rating = {
    getRating: (id) =>
      Api.axios('POST', `${getServiceUrl('player')}/api/graphql`, true, {
        query: `query match($data: MatchWhereUniqueInput!, $data2: FanratingWhereInput) {
        match(where: $data) {
          homeTeam {
          firstName
          lastName
          jerseyNumber
          image
          id
          rating(where: $data2) {
          rating
          }
          }
        }
      }`,
        variables: {
          data: {
            providerId: `${id}`,
          },
          data2: {
            matchId: {
              equals: `${id}`,
            },
          },
        },
      }),
  };
  static authorization = {
    sendSignInWithPhone: (payload) =>
      Api.axios(
        'POST',
        `${IDENTITY_TOOLKIT_URL}/accounts:sendVerificationCode?key=${firebaseConfig.apiKey}`,
        false,
        payload
      ),
    verifyPhoneCode: (payload) =>
      Api.axios(
        'POST',
        `${IDENTITY_TOOLKIT_URL}/accounts:signInWithPhoneNumber?key=${firebaseConfig.apiKey}`,
        false,
        payload
      ),
    getCustomToken: (firebaseToken) =>
      Api.axios('GET', `${getServiceUrl('authorization')}/getToken`, false, {
        header: {
          authorization: `Bearer ${firebaseToken}`,
        },
      }),
    signInWithEmailPass: (email, password) =>
      Api.axios(
        'POST',
        `${IDENTITY_RELYING_PARTY_URL}/verifyPassword?key=${firebaseConfig.apiKey}`,
        false,
        {
          email,
          password,
          returnSecureToken: true,
        }
      ),
    sendPasswordResetEmail: async (email, config) => {
      await Api.axios(
        'POST',
        `${getServiceUrl('user')}/setDeepLinkToApp`,
        false,
        {
          email,
          appName: `admin${getClubName() === 'tfg' ? '-dev' : ''}@${config?.cloudIdName}`,
        }
      );
      const graphQLClient = await this.getGraphQLClient('user', false);
      const query = gql`
        mutation SendResetCode($email: String!) {
          sendUserPasswordResetLink(email: $email)
        }
      `;
      const { sendUserPasswordResetLink } = await graphQLClient.request(query, {
        email,
      });
      if (!sendUserPasswordResetLink) {
        throw Object.assign(new Error(), { code: 'EMAIL_NOT_SENT' });
      }
      return sendUserPasswordResetLink;
    },
    redeemPasswordReset: async ({ email, token, password }) => {
      const graphQLClient = await this.getGraphQLClient('user', false);
      const query = gql`
        mutation ResetPass(
          $email: String!
          $token: String!
          $password: String!
        ) {
          redeemUserPasswordResetToken(
            email: $email
            token: $token
            password: $password
          ) {
            code
            message
          }
        }
      `;
      const { redeemUserPasswordResetToken } = await graphQLClient.request(
        query,
        {
          token,
          password,
          email,
        }
      );
      if (redeemUserPasswordResetToken) {
        throw Object.assign(new Error(), {
          code: redeemUserPasswordResetToken.code,
        });
      }
      return true;
    },
  };
  static images = {
    uploadImage: (payload) =>
      Api.axios('post', `${getServiceUrl('image')}`, true, payload),
    removeImage: (type, field, id, hash) =>
      Api.axios(
        'DELETE',
        `${getServiceUrl('image')}/${hash}?uploadEntityId=${id}&uploadEntityField=${field}&type=${type}`
      ),
    getPresignedUploadUrl: (fileName) =>
      Api.axios('post', `${getServiceUrl('image')}/presigned`, true, {
        fileName,
      }).then(({ data }) => data),
  };
  static offers = {
    getOfferViews: (payload, status) =>
      Api.axios(
        'get',
        `${getServiceUrl('stats-new')}/offers/getOffersViews/${status}`,
        true,
        payload
      ),
    getAllOfferActivations: (payload, status) =>
      Api.axios(
        'get',
        `${getServiceUrl('stats-new')}/offers/getAllOfferActivations/${status}`,
        true,
        payload
      ),
    fetchCategories: async () => {
      const { data } = await Api.axios(
        'GET',
        `${getServiceUrl('offer')}/offer/categories`,
        true
      );
      return data.map((category) => ({
        label: category.name,
        value: String(category.id),
      }));
    },
    fetchLevels: async () => {
      const results = await Api.club.getLevels();
      const levels = results.map((level) => ({
        label: level.value,
        id: level.id,
        value: level.id,
      }));
      levels.unshift({ label: 'All levels', value: null });
      return levels;
    },
    fetchGroups: async () => {
      const results = await Api.club.getGroups();
      const groups = results.map((group) => ({
        label: group.value,
        id: group.id,
        value: group.id,
      }));
      groups.unshift({ label: 'All groups', value: null });
      return groups;
    },
    fetchTiers: async (config) => {
      const results = await Api.tiers.allTiers(config);
      const tiers = results.data.map((tier) => ({
        label: tier.name,
        id: tier.id,
        value: tier.id,
      }));
      tiers.unshift({ label: 'All tiers', value: 'all' });
      tiers.unshift({ label: 'All users', value: null });
      return tiers;
    },
    fetchCompanies: async () => {
      const companies = await Api.companies.getCompaniesForClub();
      return companies;
    },
    fetchContactsFromCompany: async (id) => {
      const contacts = await Api.employees.getEmployees(id);
      return contacts;
    },
    fetchSingleOffer: async (offerId, clubId) => {
      const offer = await Api.axios(
        'GET',
        `${getServiceUrl('offer')}/offer/${offerId}/club`,
        true,
        { header: { 'fep-context': clubId } }
      );
      return offer;
    },
    fetchAllOffers: async (
      clubId,
      currentPage = 1,
      numberOfRecords = 10,
      status
    ) => {
      const offers = await Api.axios(
        'GET',
        `${getServiceUrl('offer')}/offer/club?status=${status}&page=${currentPage}&records=${numberOfRecords}`,
        true,
        { header: { 'fep-context': clubId } }
      );
      return offers;
    },
    filterOffersByCategory: async (
      category,
      clubId,
      currentPage = 1,
      numberOfRecords = 10,
      status
    ) => {
      const offers = await Api.axios(
        'GET',
        `${getServiceUrl('offer')}/offer/club?status=${status}&category=${category}&page=${currentPage}&records=${numberOfRecords}`,
        true,
        { header: { 'fep-context': clubId } }
      );
      return offers;
    },
    createOffer: async (payload, clubId) => {
      const offer = await Api.axios(
        'POST',
        `${getServiceUrl('offer')}/offer/club`,
        true,
        { header: { 'fep-context': clubId }, ...payload }
      );
      return offer;
    },
    updateOfferById: async (offerId, payload, clubId) => {
      const offer = await Api.axios(
        'PUT',
        `${getServiceUrl('offer')}/offer/${offerId}/club`,
        true,
        { header: { 'fep-context': clubId }, ...payload }
      );
      return offer;
    },
    pinOfferById: async (offerId, payload, clubId) => {
      const offer = await Api.axios(
        'PUT',
        `${getServiceUrl('offer')}/offer/${offerId}/pin`,
        true,
        { header: { 'fep-context': clubId }, ...payload }
      );
      return offer;
    },
    deleteOfferById: async (offerId, clubId) => {
      const offer = await Api.axios(
        'DELETE',
        `${getServiceUrl('offer')}/offer/${offerId}/club`,
        true,
        { header: { 'fep-context': clubId } }
      );
      return offer;
    },
    contactOffer: async (offerId, clubId, payload) => {
      const response = await Api.axios(
        'POST',
        `${getServiceUrl('offer')}/offer/communicate/${offerId}`,
        true,
        { header: { 'fep-context': clubId }, ...payload }
      );
      return response;
    },
    updateScheduledMessage: async (offerId, messageId, clubId, payload) => {
      return Api.axios(
        'PUT',
        `${getServiceUrl('offer')}/offer/communicate/scheduleMessage/${offerId}/${messageId}`,
        true,
        { header: { 'fep-context': clubId }, ...payload }
      );
    },
    deleteScheduledMessage: async (offerId, messageId) => {
      return Api.axios(
        'DELETE',
        `${getServiceUrl('offer')}/offer/communicate/scheduleMessage/${offerId}/${messageId}`,
        true
      );
    },
  };
  static scheduleMessages = {
    sendMessage: (payload) =>
      Api.axios(
        'post',
        `${getServiceUrl('user-term')}/message/scheduledMessage`,
        true,
        payload
      ),
    updateMessage: (payload, messageID) =>
      Api.axios(
        'PUT',
        `${getServiceUrl('user-term')}/message/scheduledMessage/${messageID}`,
        true,
        payload
      ),
    deleteMessage: (messageID) =>
      Api.axios(
        'DELETE',
        `${getServiceUrl('user-term')}/message/scheduledMessage/${messageID}`,
        true
      ),
    getScheduleMessagesByServiceId: (serviceId, page, pageSize) =>
      Api.axios(
        'GET',
        `${getServiceUrl('communication')}/scheduled/byServiceId/${serviceId}?page=${page}&count=${pageSize}`,
        true
      ),
    getScheduleMessagesByServiceIdByStatus: (
      serviceId,
      page,
      pageSize,
      status
    ) =>
      Api.axios(
        'GET',
        `${getServiceUrl('communication')}/scheduled/byServiceId/${serviceId}?page=${page}&count=${pageSize}&status=${status}`,
        true
      ),
    getScheduleMessages: (page, pageSize, status = undefined) =>
      Api.axios(
        'GET',
        `${getServiceUrl('communication')}/scheduled?page=${page}&count=${pageSize}${status ? `&status=${status}` : ''} `,
        true
      ),
    getStatsForMessages: (pushStatIds, onReceive) =>
      Api.getStreamableData(
        `${getServiceUrl('stats-new')}/communication/getPushStats?pushStatIds[]=${pushStatIds.join('&pushStatIds[]=')}`,
        onReceive
      ),
  };
  static questions = {
    fetchSharedQuestions: async (clubId) => {
      const questions = await Api.axios(
        'GET',
        `${getServiceUrl('question')}/question/shared/event`,
        true,
        { header: { 'fep-context': clubId } }
      );
      return questions;
    },
    fetchDomainSpecificQuestions: async (eventId, clubId) => {
      const questions = await Api.axios(
        'GET',
        `${getServiceUrl('question')}/question/domain/${eventId}`,
        true,
        { header: { 'fep-context': clubId } }
      );
      return questions;
    },
    searchSharedQuestions: async (payload, clubId) => {
      const questions = await Api.axios(
        'POST',
        `${getServiceUrl('question')}/question/shared/search`,
        true,
        { header: { 'fep-context': clubId }, ...payload }
      );
      return questions;
    },
    fetchAnswers: async (eventId) =>
      Api.axios('GET', `${getServiceUrl('question')}/answer/${eventId}`, true),
    saveAnswers: async (eventId, payload) => {
      const questions = await Api.axios(
        'POST',
        `${getServiceUrl('question')}/answer/${eventId}`,
        true,
        { ...payload }
      );
      return questions;
    },
    createSharedQuestion: async (payload, clubId) => {
      const questions = await Api.axios(
        'POST',
        `${getServiceUrl('question')}/question/shared`,
        true,
        { header: { 'fep-context': clubId }, ...payload }
      );
      return questions;
    },
    createDomainQuestions: async (payload, clubId) => {
      const questions = await Api.axios(
        'POST',
        `${getServiceUrl('question')}/question/domain`,
        true,
        { header: { 'fep-context': clubId }, ...payload }
      );
      return questions;
    },
    updateQuestion: async (id, payload, clubId) => {
      const questions = await Api.axios(
        'PATCH',
        `${getServiceUrl('question')}/question/${id}`,
        true,
        { header: { 'fep-context': clubId }, ...payload }
      );
      return questions;
    },
    deleteDomainQuestion: async (domainId, payload) => {
      const questions = await Api.axios(
        'DELETE',
        `${getServiceUrl('question')}/question/domain/${domainId}`,
        true,
        { ...payload }
      );
      return questions;
    },
    deleteSharedQuestion: async (id) => {
      const questions = await Api.axios(
        'DELETE',
        `${getServiceUrl('question')}/question/shared/${id}`,
        true
      );
      return questions;
    },
    deleteParticipantAnswers: async (eventId, userId) => {
      const questions = await Api.axios(
        'DELETE',
        `${getServiceUrl('question')}/admin/answer/${eventId}/${userId}`,
        true
      );
      return questions;
    },
    deleteAllAnswersInEvent: async (eventId, userId) => {
      const questions = await Api.axios(
        'POST',
        `${getServiceUrl('question')}/admin/answer/multiple/${eventId}`,
        true,
        userId
      );
      return questions;
    },
    deleteAllQuestionsInDomain: async (eventId) => {
      const questions = await Api.axios(
        'DELETE',
        `${getServiceUrl('question')}/admin/question/domain/${eventId}`,
        true
      );
      return questions;
    },
  };
  static matchCenter = {
    getUpcomingMatches: async (page, records, teams, concluded) =>
      Api.axios(
        'GET',
        `${getServiceUrl('matchinfo')}/match/upcoming?page=${page}&records=${records}&concluded=${concluded}`,
        true,
        { header: { teams: JSON.stringify(teams) } }
      ),
    getCustomMatch: async (matchId) =>
      Api.axios(
        'GET',
        `${getServiceUrl('matchinfo')}/match/upcoming/custom/${matchId}`
      ),
    updateUpcomingMatch: async (clubId, matchId, payload) =>
      Api.axios(
        'PATCH',
        `${getServiceUrl('matchinfo')}/match/upcoming/${matchId}`,
        true,
        { header: { 'fep-context': clubId }, ...payload }
      ),
    createCustomMatch: async (payload) =>
      Api.axios(
        'POST',
        `${getServiceUrl('matchinfo')}/match/createCustomClubMatch`,
        true,
        payload
      ),
    updateCustomMatch: async (matchId, payload) =>
      Api.axios(
        'PUT',
        `${getServiceUrl('matchinfo')}/match/updateCustomClubMatch/${matchId}`,
        true,
        payload
      ),
    listCustomMatches: async (concluded) =>
      Api.axios(
        'GET',
        `${getServiceUrl('matchinfo')}/match/list/custom?concluded=${concluded}`
      ),
    getUpcomingMatch: async (id) =>
      Api.axios(
        'GET',
        `${getServiceUrl('matchinfo')}/match/upcoming/${id}`,
        true
      ),
    deleteMatch: async (matchId) =>
      Api.axios(
        'DELETE',
        `${getServiceUrl('matchinfo')}/match/${matchId}`,
        true
      ),
  };
  static mvpPrize = {
    getAllPrizes: async () =>
      Api.axios('GET', `${getServiceUrl('matchinfo')}/admin/prize`).then(
        ({ data }) => data
      ),
    getPrizeById: async (prizeId) =>
      Api.axios(
        'GET',
        `${getServiceUrl('matchinfo')}/admin/prize/${prizeId}`
      ).then(({ data }) => data),
    createPrize: async (payload) =>
      Api.axios(
        'POST',
        `${getServiceUrl('matchinfo')}/admin/prize`,
        true,
        payload
      ),
    updatePrize: async (prizeId, payload) =>
      Api.axios(
        'PUT',
        `${getServiceUrl('matchinfo')}/admin/prize/${prizeId}`,
        true,
        payload
      ),
    deletePrize: async (prizeId) =>
      Api.axios(
        'DELETE',
        `${getServiceUrl('matchinfo')}/admin/prize/${prizeId}`,
        true
      ),
  };
  static questionaire = {
    getQuestions: ({ page, take }, { orderBy, direction }) =>
      Api.axios(
        'get',
        `${getServiceUrl('question')}/admin/questionaire-question?page=${page}&take=${take}&orderBy=${orderBy}&direction=${direction}`
      ).then(({ data }) => data),
    getQuestion: (questionId) =>
      Api.axios(
        'get',
        `${getServiceUrl('question')}/admin/questionaire-question/${questionId}`
      ).then(({ data }) => data),
    createQuestion: (payload) =>
      Api.axios(
        'post',
        `${getServiceUrl('question')}/admin/questionaire-question`,
        true,
        payload
      ).then(({ data }) => data),
    deleteQuestion: (questionId) =>
      Api.axios(
        'delete',
        `${getServiceUrl('question')}/admin/questionaire-question/${questionId}`
      ),
    getQuestionsForQuestionaire: (questionaireId) =>
      Api.axios(
        'get',
        `${getServiceUrl('question')}/admin/questionaire-question/questions/${questionaireId}`
      ).then(({ data }) => data),
    updateQuestion: (questionId, payload) =>
      Api.axios(
        'put',
        `${getServiceUrl('question')}/admin/questionaire-question/${questionId}`,
        true,
        payload
      ),

    getQuestionaires: ({ page, take }, { orderBy, direction }) =>
      Api.axios(
        'get',
        `${getServiceUrl('question')}/admin/questionaire?page=${page}&take=${take}&orderBy=${orderBy}&direction=${direction}`
      ).then(({ data }) => data),
    getQuestionaire: (questionaireId) =>
      Api.axios(
        'get',
        `${getServiceUrl('question')}/admin/questionaire/${questionaireId}`
      ).then(({ data }) => data),
    createQuestionaire: (payload) =>
      Api.axios(
        'post',
        `${getServiceUrl('question')}/admin/questionaire`,
        true,
        payload
      ).then(({ data }) => data),
    updateQuestionaire: (questionaireId, payload) =>
      Api.axios(
        'put',
        `${getServiceUrl('question')}/admin/questionaire/${questionaireId}`,
        true,
        payload
      ),
    getQuestionaireAccess: (questionaireId) =>
      Api.axios(
        'get',
        `${getServiceUrl('question')}/admin/access/${questionaireId}`
      ).then(({ data }) => data),
    updateQuestionaireAccess: (questionaireId, payload) =>
      Api.axios(
        'put',
        `${getServiceUrl('question')}/admin/access/${questionaireId}`,
        true,
        payload
      ),
    deleteQuestionaire: (questionaireId) =>
      Api.axios(
        'delete',
        `${getServiceUrl('question')}/admin/questionaire/${questionaireId}`
      ),
    getQuestionairesViewAmount: (payload) =>
      Api.axios(
        'get',
        `${getServiceUrl('stats-new')}/questionaire/getQuestionaireViews`,
        true,
        payload
      ),
    getStatsForQuestionaire: (questionaireId) =>
      Api.axios(
        'get',
        `${getServiceUrl('question')}/admin/questionaire-answer/${questionaireId}/stats`
      ).then(({ data }) => data),
    getAnswersForQuestionaire: (questionaireId) =>
      Api.axios(
        'get',
        `${getServiceUrl('question')}/admin/questionaire-answer/${questionaireId}`
      ).then(({ data }) => data),
    getTableAnswersForQuestionaire: (
      { page, take },
      { orderBy, direction },
      questionaireId
    ) =>
      Api.axios(
        'get',
        `${getServiceUrl('question')}/admin/questionaire-answer/${questionaireId}/table?page=${page}&take=${take}&orderBy=${orderBy}&direction=${direction}`
      ).then(({ data }) => data),

    linkQustions: (questionaireId, payload) =>
      Api.axios(
        'put',
        `${getServiceUrl('question')}/admin/questionaire-question/questions/${questionaireId}`,
        true,
        payload
      ),

    contactQuestionaire: async (questionaireId, payload) =>
      Api.axios(
        'POST',
        `${getServiceUrl('question')}/admin/communication/${questionaireId}`,
        true,
        payload
      ),
    updateScheduledMessage: async (questionaireId, messageId, payload) => {
      return Api.axios(
        'PUT',
        `${getServiceUrl('question')}/admin/communication/${questionaireId}/${messageId}`,
        true,
        payload
      );
    },
    deleteScheduledMessage: async (messageId) => {
      return Api.axios(
        'DELETE',
        `${getServiceUrl('question')}/admin/communication/${messageId}`,
        true
      );
    },
  };
}
