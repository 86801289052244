import React from 'react';
import {
  ButtonToolbar,
  Table,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import { Link } from 'react-router-dom';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import ArrowUpIcon from 'mdi-react/ArrowUpIcon';
import ArrowDownIcon from 'mdi-react/ArrowDownIcon';
import CloudDownloadIcon from 'mdi-react/CloudDownloadIcon';
import moment from 'moment';
import { toast } from 'react-toastify';
import skeletonTableLoader from '../../../shared/components/custom/SkeletonLoaders';
import Pagination from '../../../shared/components/pagination/Pagination';
import EmptyStateInfo from '../../../shared/components/custom/EmptyStateInfo';
import t, { partial } from '../../../util/translation/translation';
import { getBaseDate } from '../../../util/date-and-time';
import Api from '../../../util/api';
import config from '../../../config';
import CheckIcon from '../../../shared/components/custom/CheckIcon';

const UNLIMITED_DATE = '9999-12-31T00:00:00.000Z';
const NewsList = ({
  news,
  loading,
  onChangePage,
  handleSearch,
  handleInput,
  handleModal,
  handleSort,
  order,
  setLoadingNews,
  getNews,
}) => {
  const s = partial('shared');
  const p = partial('NewsFormPage');

  const renderSort = (cellOrder) => {
    if (order.orderBy !== cellOrder) {
      return undefined;
    }
    return order.desc ? <ArrowDownIcon size={16} /> : <ArrowUpIcon size={16} />;
  };
  const getStatusBadge = ({
    access: { visible },
    publicationDate,
    expirationDate,
  }) => {
    if (!visible) {
      return { color: 'secondary', text: p('draft') };
    }
    if (moment().isBefore(moment(publicationDate))) {
      return { color: 'warning', text: t('ScheduledMessagesPage.Scheduled') };
    }
    if (
      moment().isAfter(moment(publicationDate)) &&
      moment().isBefore(moment(expirationDate))
    ) {
      return { color: 'success', text: p('published') };
    }
    if (moment().isAfter(moment(expirationDate))) {
      return { color: 'danger', text: s('inActive') };
    }
    return { color: 'danger', text: s('somethingWentWrong') };
  };
  const renderNews = () => {
    if (loading) {
      return skeletonTableLoader(4, 8, 70);
    }

    return news.map((n) => {
      const newsType =
        n.type?.toUpperCase() === 'CUSTOM'
          ? s('custom').toUpperCase()
          : n.type?.toUpperCase();
      const status = getStatusBadge(n);

      return (
        <tr key={`news-${n.id}`} className="center-list">
          <td>
            <Link to={`/news/edit/${n.id}`}>{n.title}</Link>
          </td>
          <td>{getBaseDate(n.publicationDate)}</td>
          <td>
            {n.expirationDate !== UNLIMITED_DATE
              ? getBaseDate(n.expirationDate)
              : '-'}
          </td>
          <td>{newsType || ''}</td>
          {config.clubWebsiteEnabled && (
            <td className="flex-Hcenter">
              <CheckIcon state={!!n.isFeatured} />
            </td>
          )}
          <td>{n.categories.length ? n.categories.join(',') : '-'}</td>
          <td>
            {n.presses !== undefined
              ? n.presses
              : skeletonTableLoader(1, 1, 50)}
          </td>
          <td>{n.likes || 0}</td>
          <td>
            <Badge color={status.color}>{status.text}</Badge>
          </td>
          <td>
            <UncontrolledDropdown className="dashboard__table-more">
              <DropdownToggle>
                <DotsHorizontalIcon />
              </DropdownToggle>
              <DropdownMenu className="dropdown__menu">
                <Link to={`/news/edit/${n.id}`}>
                  <DropdownItem>{s('edit')}</DropdownItem>
                </Link>
                <DropdownItem
                  onClick={() => handleModal(true, n.id, 'news')}
                  className="danger"
                >
                  {s('delete')}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      );
    });
  };
  const refreshNews = async () => {
    setLoadingNews(true);
    try {
      await Api.news.refreshNews();
      setTimeout(() => getNews(), 3000);
    } catch (err) {
      setLoadingNews(false);
      toast.error(p('fetchingNewsFailed'));
    }
  };
  return (
    <>
      <div className="flex justify-content-end">
        <Link to="/news/create">
          <Button size="sm" color="primary">
            {s('create')} {s('news')}
          </Button>
        </Link>
      </div>
      <div className="flex space-between">
        <div>
          <h3 className="page-title mb-0">{s('news')}</h3>
        </div>
        <div>
          <ButtonToolbar className="margin-top-0">
            <div className="form">
              <div className="form__form-group products-list__search">
                <input
                  placeholder={s('search')}
                  value={news.searchText}
                  onKeyPress={(event) => handleSearch(event.key)}
                  onChange={(event) => handleInput(event.target.value)}
                  name="search"
                />
                <MagnifyIcon />
              </div>
            </div>
            <Button size="sm" outline color="secondary" onClick={refreshNews}>
              <CloudDownloadIcon /> {p('fetchNews')}
            </Button>
          </ButtonToolbar>
        </div>
      </div>

      {!!news && news?.length === 0 && !loading && (
        <div className="my-3">
          <EmptyStateInfo
            icon="newspaper"
            url="/news/create"
            linkName={p('createNews')}
            title={p('createTitle')}
            text={p('createText')}
          />
        </div>
      )}
      {(loading || news?.length > 0) && (
        <>
          <div
            style={{
              display: 'flex',
              paddingBottom: 5,
              marginBottom: 10,
              background: '#fff',
              borderRadius: 6,
              boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.08)',
            }}
          >
            <Table responsive striped>
              <thead>
                <tr>
                  <th>
                    <div className="flex alignItems-center">
                      {s('heading')} {renderSort('title')}
                    </div>
                  </th>
                  <th
                    className="sortable"
                    onClick={() => handleSort('publicationDate')}
                  >
                    <div className="flex alignItems-center">
                      {s('publicationDate')} {renderSort('publicationDate')}
                    </div>
                  </th>
                  <th
                    className="sortable"
                    onClick={() => handleSort('expirationDate')}
                  >
                    <div className="flex alignItems-center">
                      {s('expirationDate')} {renderSort('expirationDate')}
                    </div>
                  </th>
                  <th>
                    <div className="flex alignItems-center">{s('type')}</div>
                  </th>
                  {config.clubWebsiteEnabled && (
                    <th
                      className="sortable"
                      onClick={() => handleSort('isFeatured')}
                    >
                      <div className="flex alignItems-center">
                        {s('featuredNews')} {renderSort('isFeatured')}
                      </div>
                    </th>
                  )}
                  <th>
                    <div className="flex alignItems-center">{s('views')}</div>
                  </th>
                  <th className="sortable" onClick={() => handleSort('likes')}>
                    <div className="flex alignItems-center">
                      {s('likes')} {renderSort('likes')}
                    </div>
                  </th>
                  <th>{s('status')}</th>
                  <th />
                </tr>
              </thead>
              <tbody>{renderNews()}</tbody>
            </Table>
          </div>
          <Pagination
            items={order.total}
            onChangePage={onChangePage}
            initialPage={0}
          />
        </>
      )}
    </>
  );
};

export default NewsList;
